import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {
            const locale = matched[1];

            messages[locale] = locales(key);
        }
    });
    return messages;
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'cs',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'cs',
    messages: loadLocaleMessages(),
    pluralizationRules: {
        cs: (amount, choicesLength) => {
            if (choicesLength === 2) {
                if (amount === 1) return 0;
                return 1;
            }

            if (choicesLength === 3) {
                if (amount === 0) return 0;
                if (amount === 1) return 1;
                return 2;
            }

            if (amount === 0) return 0;
            if (amount === 1) return 1;
            if (amount >= 2 && amount <= 4) return 2;
            return 3;
        },
    },
});
