<template>
    <q-card class="bg-yellow-1">
        <q-card-section>
            <q-list dense bordered padding class="rounded-borders">
                <q-item clickable v-ripple :active="selectedPromoter === p" v-for="p in filteredPromoters" :key="p.id" @click="togglePromoter(p)">
                    <q-item-section>
                        {{ p.title }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
    </q-card>
</template>

<script>
export default {
    data() {
        return {
            listArchived: false,
        };
    },

    computed: {
        filteredPromoters() {
            return (this.promoters || []).filter(p => this.listArchived ? p.archived : !p.archived);
        },
    },

    methods: {
        newPromoter() {
            this.selectedPromoter = this.getNewPromoter();
        },
    },
};
</script>
