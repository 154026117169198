import Vue from 'vue';
import _ from 'lodash';

const requireComponent = require.context('@/components');

for (const fileName of requireComponent.keys()) {
    const componentConfig = requireComponent(fileName);
    const componentName = _.upperFirst(_.camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')));

    Vue.component(componentName, componentConfig.default || componentConfig);
}
