<template>
    <q-select
        popup-content-class="my-input-select__option"
        class="my-input-select venues"
        dropdown-icon="svguse:/icons.svg#carret-down--stroke"
        input-debounce="0"
        behavior="menu"
        outlined
        emit-value
        map-options
        label="Vybrat lokaci"
        :options="venues"
        :option-label="o => loc(o.name)"
        @input="$emit('input', $event)"
    />
        <!-- <span v-if="value">{{ getVenueName(value) }}</span>
        <span v-else class="text-grey">{{ getVenueName(fallback) || fallback || '...' }}</span>
        <PopupEdit :value="value" @input="$emit('input', $event)" @save="$emit('save', $event)" @cancel="$emit('cancel', $event)">
            <q-select :value="value" @input="$emit('input', $event)" dense autofocus clearable emit-value map-options
                      :options="venues" option-value="id" :option-label="o => loc(o.name)" />
        </PopupEdit> -->
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: null
        }
    },
   data() {
       return {
           venue: null,
       }
   },
   watch: {
    venue() {
        console.log(this.venue)
    }
   },
   mounted() {
    console.log(this.value)
    if (this.value && this.value.length) {
        this.venue = this.value
    } else {
        return null
    }
    console.log(this.venue)
   }
};
</script>
