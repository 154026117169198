<template>
    <q-card :class="isArchived ? 'bg-grey-4' : 'bg-green-1'">
        <q-card-section :class="`text-body2 text-${validationColor}`">
            <q-icon :name="validationIcon" :color="validationColor" />
            <span>{{ validationText }}</span>
        </q-card-section>
        <q-card-section>
            <LangEdit code="cs" :values="selectedVenue.name" fallback="⧼ Název CZ ⧽" :iconSize="24" textClass="h5" />
            <LangEdit code="en" :values="selectedVenue.name" fallback="⧼ Název EN ⧽" :iconSize="24" textClass="h6" />
        </q-card-section>
        <q-card-section>
            <div><strong>Adresa:</strong> <TextEdit v-model="selectedVenue.address" fallback="⧼ Adresa ⧽" title="Adresa" tag="span" /></div>
        </q-card-section>
        <q-card-section v-if="!isArchived" class="q-gutter-sm">
            <SaveButton :saveFn="save" :disable="saveBlocker" />
            <DeleteButton v-if="selectedVenue.id" :deleteFn="del" type="venue" :item="selectedVenue" />
            <q-btn v-else label="Zrušit" @click="selectedVenue = null" />
        </q-card-section>
        <q-btn flat round dense class="absolute-top-right q-ma-sm" color="grey" icon="close" @click="selectedVenue = null;" />
    </q-card>
</template>

<script>
export default {
    computed: {
        isArchived() {
            return this.selectedVenue.archived;
        },
        saveBlocker() {
            return (
                (this.isArchived && "Lokace byla archivována") ||
                (!this.selectedVenue.name.cs && "Chybí český název") ||
                (!this.selectedVenue.address && "Chybí adresa")
            );
        },
        validationText() {
            return this.saveBlocker || "Lokace je plně validní";
        },
        validationColor() {
            return this.isArchived ? 'blue-grey-5' : this.saveBlocker ? 'red' : 'green';
        },
        validationIcon() {
            return this.isArchived ? 'close' : this.saveBlocker ? 'warning' : 'check';
        },
    },

    methods: {
        async save() {
            if (this.selectedVenue.id) {
                const venue = await this.update('venues', this.selectedVenue);

                this.updateIn(this.venues, venue);
                this.selectedVenue = venue;
            } else {
                const venue = await this.create('venues', this.selectedVenue);

                this.venues.push(venue);
                this.selectedVenue = venue;
            }

            notifyOk("Lokace byla uložena.");
        },
        async del() {
            const { result, archived } = await this.delete('venues', this.selectedVenue);

            if (result === 'destroyed') {
                notifyOk("Lokace byla smazána.");

                this.deleteIn(this.venues, this.selectedVenue);
            } else if (result === 'archived') {
                notifyOk("Lokace byla archivována.");

                this.selectedVenue.archived = archived;

                this.updateIn(this.venues, this.selectedVenue);
            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }

            this.selectedVenue = null;
        },
    },
};
</script>
