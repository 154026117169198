<template>
    <q-select
        class="my-input-select discounts"
        popup-content-class="my-input-select__option"
        dropdown-icon="svguse:/icons.svg#carret-down--stroke"
        ref="qSelect"
        v-bind="$attrs"
        :options="filteredOptions"
        @input="$emit('input', $event)"
        @add="onAdd"
        @remove="onRemove"
        @filter="onFilter"
    >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </q-select>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        // filterFn: {
        //     type: Function,
        //     required: true,
        // },
    },

    data() {
        return {
            filteredOptions: this.options,
        };
    },

    methods: {
        onAdd() {
            this.$refs.qSelect.updateInputValue('');

            this.$nextTick(() => {
                this.$refs.qSelect.filter('');
            });
        },
        onRemove() {
            this.$nextTick(() => {
                this.$refs.qSelect.filter('');
            });
        },
        // onFilter(text, update) {
        //     const that = this;

        //     update(() => {
        //         this.filteredOptions = that.options.filter(o => that.filterFn(o, text));
        //     });
        // },
    },
};
</script>
