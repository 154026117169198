<!--suppress HtmlUnknownAttribute -->
<template>
    <div @click="focus" class="input-inline-heading" :class="[{empty: !value, focused, error}, size]">
        <span class="input-inline-heading__title small-label">{{ label }}</span>
        <div class="input-inline-heading__inner">
            <h2 v-if="!focused" class="typography-h2 input-inline-heading__visible">
                {{ value || inputPlaceholder }}
                <q-icon v-if="!focused" :size="`${size!=='big'? '16' : '20'}px`" name="svguse:/icons.svg#pencil"/>
            </h2>
            <textarea
                :tabindex="tabindex"
                v-show="focused"
                @blur="focusOut"
                @keypress.enter="focusOut"
                data-text="123"
                ref="input"
                class="typography-h2"
                :placeholder="inputPlaceholder || ''"
                v-model="value"
            />
        </div>
    </div>
</template>

<script>
import './input-inline-heading.scss'

export default {
    props: {
        tabindex:{
            type: Number,
            default: 0
        },
        initialValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Československá divadelní '
        },
        label: {
            type: String,
            default: 'Title '
        },
        size: {
            type: String,
            default: 'big '
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    name: "InputInlineHeading",
    data: () => ({
        value: null,
        focused: false,
        selected: ''
    }),
    watch: {
        initialValue: {
            immediate: true,
            handler(val) {
                this.value = val
            }
        },
        focused() {
            setTimeout(() => {
                if (this.focused && this.$refs.input) {
                    let height = ''
                    if (this.size === 'big') height = 32
                    if (this.size === 'medium') height = 24
                    if (this.size === 'small') height = 18
                    this.$refs.input.style.height = height + "px";
                }
            }, 0)
            setTimeout(() => {
                if (this.focused && this.$refs.input) {
                    this.$refs.input.style.height = (this.$refs.input.scrollHeight) + "px";
                }
            }, 0)

        },
        value() {
            this.$emit('input', this.value)
            if (this.focused && this.$refs.input) {
                this.$refs.input.style.height = (this.$refs.input.scrollHeight) + "px";
            }
        }

    },
    computed: {
        inputPlaceholder() {
            return this.focused ? '' : this.placeholder
        },
    },
    methods: {
        focus() {
            this.focused = true
            setTimeout(() => {
                this.$refs.input.focus()
            }, 0)
        },
        focusOut() {
            this.focused = false
        },

    },
    beforeMount() {
        if (this.initialValue) {
            this.value = this.initialValue
        }
    }
}
</script>
