<template>
    <q-layout view="hHh Lpr lff" class="shadow-2">
        <q-page-container>
            <q-page class="">
                <router-view/>
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
export default {
    computed: {
        navShown() {
            if (this.$route.path.includes('login')) return false
            return (
                this.$route.fullPath.startsWith('/admin/') ||
                (appConfig.storefront.showDevUi && this.loggedIn && localStorage.getItem('authRole') === 'admin')
            );
        },
        logoutShown() {
            return this.$route.name !== 'login' && this.loggedIn;
        },
    },
    data: () => ({
        drawer: false,
        miniState: false,
    }),

    created() {
        const authToken = localStorage.getItem('authToken');

        if (authToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

            this.loggedIn = true;
        }
    },

    meta: {
        titleTemplate: title => `${title} | MeetFactory Ticketing`,
    },
};
</script>
