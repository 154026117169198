<template>
    <div class="card-category" v-if="content" :class="{content}">
        <div class="row justify-between items-center card-category__head no-wrap">
            <div class="row items-center justify-start">
                <div :class="`q-mr-md bg-${validationColor}`">
                    <q-icon v-if="saveBlocker" name="svguse:/icons.svg#warning" size="24px" title="připraveno" />
                    <!-- <q-icon v-else-if="!isAux && sellBlocker" class="q-mb-xs" name="warning" color="orange" />
                    <q-icon v-else-if="isAux && sellBlocker" class="q-mb-xs" name="mail" color="blue" /> -->
                    <q-icon v-else name="svguse:/icons.svg#check-thin" size="24px" title="připraveno" />
                </div>
                <h3 class="typography-h3 q-ma-none">{{ content.name.cs }}</h3>
            </div>
            <button @click.stop="" class="card-category__trash-wrapper" @click="$emit('remove')">
                <q-icon class="card-category__trash my-icon" name="svguse:/icons.svg#trash"/>
            </button>
        </div>
        <div class="row items-center justify-between">
            <div>
                <div class="small-label">Cena</div>
                <div class="body-medium">{{ content.price }}</div>
            </div>
            <div>
                <div class="small-label">Prodané interní</div>
                <div class="body-medium">{{ content.blockedCount + '/' + content.ticketCount }}</div>
            </div>
            <div>
                <div v-if="content.externalTicketCodes && content.externalTicketCodes.length" class="small-label">Prodané externí</div>
                <div v-if="content.externalTicketCodes && content.externalTicketCodes.length" class="body-medium">{{ content.externalTicketCodes && content.externalTicketCodes.length }}</div>
            </div>
        </div>
    </div>
    <div class="card-category card-category--skeleton" v-else>
        <div class="row justify-between items-center card-category__head">
            <h3 class="typography-h3 q-ma-none"></h3>
            <button class="card-category__trash-wrapper" @click="$emit('remove')">
                <q-icon class="card-category__trash my-icon" name="svguse:/icons.svg#close"/>
            </button>
        </div>
        <div class="row items-center justify-between">
            <div class="card-category__button">
                <div class="small-label"></div>
                <div class="body-medium"></div>
            </div>
            <div class="card-category__button">
                <div class="small-label"></div>
                <div class="body-medium"></div>
            </div>
            <div class="card-category__button">
                <div class="small-label"></div>
                <div class="body-medium"></div>
            </div>
        </div>
    </div>
</template>

<script>
import './card-category.scss'

export default {
    name: "CardCategory",
    props: {
        content: {
            type: Object,
            default: null
        },
        forEvent: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        validationColor() {
            if (this.content.linkToken && this.content.linkToken.length) {
                return '#D1FAE5'
            } else {
                return '#FCCACA'
            }
        },
        soldInternalTicketCount() {
            return this.tgSoldInternalTicketCount(this.content);
        },
        saveBlocker() {
            return this.tgSaveBlocker(this.content, this.selectedUberEvent, this.forEvent ? this.selectedEvent : null);
        },
        sellBlocker() {
            return this.tgSellBlocker(this.content, this.selectedUberEvent, this.forEvent ? this.selectedEvent : null);
        },
        markAsSoldOut() {
            return !this.saveBlocker && !this.sellBlocker && this.soldInternalTicketCount === this.content.ticketCount;
        },
        isAux() {
            return this.tgIsAux(this.content, this.selectedUberEvent);
        },
    }
}
</script>

<style lang="scss">

.card-category {
    width: 350px;
}

</style>
