<template>
    <fragment>
        <div class="float-right q-mr-lg q-pr-md">
            <q-btn v-if="!isTemplate" outline color="primary" label="Exportovat CSV " @click.stop="exportCodesCSV(null, ticketsForExport, forEvent, true, freeTickets)" />
            <q-btn v-if="!isTemplate" outline color="primary" label="Exportovat kódy pro kategorii" @click.stop="exportCodes(null, ticketsForExport, forEvent, true)" />
            <q-toggle v-if="template" v-model="selectedTicketGroup.inherit" />
        </div>
        <div v-if="!isTemplate && !isArchived && !isPast" class="q-mb-xs">
            <div :class="`text-body2 text-${validationColor}`">
                <q-icon :name="validationIcon" :color="validationColor" />
                <span>{{ validationText }}</span>
            </div>
            <div v-if="isAux && selectedTicketGroup.auxTicketsFree === false && !selectedTicketGroup.price" class="text-body2 text-orange">
                <q-icon name="warning" color="orange" />
                <span>Auxiliární vstupenky nejsou zdarma, ale chybí cena</span>
            </div>
        </div>
        <div class="column q-mt-md">
            <div class="row q-col-gutter-md">
                <div class="col-6">
                    <div class="q-gutter-sm">
                        <div class="q-mb-sm">
                            <TextEdit v-model="selectedTicketGroup.label" :fallback="tplx('label') || '⧼ Interní název ⧽'" :textClass="labelTextClass" tag="span" title="Interní název (nepovinný)" />
                            <LangEdit code="cs" :values="selectedTicketGroup.name" :fallback="tplx('name.cs') || '⧼ Název CZ ⧽'" :iconSize="24" textClass="h6" />
                            <LangEdit code="en" :values="selectedTicketGroup.name" :fallback="tplx('name.en') || '⧼ Název EN ⧽'" :iconSize="24" textClass="h6" />
                            <LangEdit code="cs" :values="selectedTicketGroup.description" :fallback="tplx('description.cs') || '⧼ Popis CZ ⧽'" :iconSize="18" textClass="subtitle1" />
                            <LangEdit code="en" :values="selectedTicketGroup.description" :fallback="tplx('description.en') || '⧼ Popis EN ⧽'" :iconSize="18" textClass="subtitle1" />
                        </div>
                        <div><strong>Cena:</strong> <NumberEdit v-model="selectedTicketGroup.price" :fallback="tplx('price') || '?'" suffix=" Kč" tag="span" /></div>
                        <div>
                            <strong v-if="isTemplate">Počet:</strong>
                            <strong v-else>Interní vstupenky:</strong>
                            <NumberEdit v-model="selectedTicketGroup.ticketCount" :fallback="tplx('ticketCount') || '?'" tag="span" />
                            <span v-if="!isTemplate" class="text-italic">(prodáno: {{ soldInternalTicketCount }})</span>
                        </div>
                        <div v-if="!isTemplate">
                            <span class="vertical-middle">
                                <strong>Externí vstupenky:</strong>
                                <span class="q-mr-xs">{{ selectedTicketGroup.externalTicketCodes.length }}</span>
                                <q-btn round dense color="grey" size="xs" icon="list" @click.stop="showExternalTickets" />
                                <q-btn round dense color="green" size="xs" icon="add" @click.stop="externalTicketAction = 'add'" />
                                <q-btn round dense color="red" size="xs" icon="remove" @click.stop="externalTicketAction = 'remove'" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="q-gutter-sm">
                        <div><strong><q-toggle v-model="(template || selectedTicketGroup).isListed" label="Viditelné" left-label :disable="!!template" /></strong></div>
                        <div v-if="!isTemplate" class="q-pb-sm">
                            <strong>Odkaz:</strong>
                            <span v-if="!selectedTicketGroup.linkToken || selectedTicketGroup.linkToken === 'new'" class="text-italic">bude vygenerován při uložení</span>
                            <TokenLink v-else :token="selectedTicketGroup.linkToken" newTab />
                            <q-btn v-if="isEditable" dense color="primary" label="Přegenerovat" class="q-ml-xs q-px-sm q-py-none" @click.stop="regenerateLinkToken" />
                        </div>
                        <div @click.stop>
                            <strong>Předprodej:</strong>
                            <FallbackCascade :format="formatDate" :values="[
                                selectedTicketGroup.publicFrom,
                                tgFallbackPublicFrom(selectedUberEvent, forEvent ? selectedEvent : null, selectedTicketGroup),
                            ]" />
                            <span> – </span>
                            <FallbackCascade :format="formatDate" :values="[
                                selectedTicketGroup.publicTo,
                                tgFallbackPublicTo(selectedUberEvent, forEvent ? selectedEvent : null, selectedTicketGroup),
                            ]" />
                            <DateTimeRangePopup showTime
                                                :from.sync="selectedTicketGroup.publicFrom"
                                                :to.sync="selectedTicketGroup.publicTo"
                                                :fallbackFrom="tgFallbackPublicFrom(selectedUberEvent, forEvent ? selectedEvent : null, selectedTicketGroup)"
                                                :fallbackFromLabel="tplx('publicFrom') ? 'Začátek dle šablony' : 'Od začátku prodeje události'"
                                                :useFallbackFrom="true"
                                                :defaultFrom="tomorrow()"
                                                :fallbackTo="tgFallbackPublicTo(selectedUberEvent, forEvent ? selectedEvent : null, selectedTicketGroup)"
                                                :fallbackToLabel="tplx('publicTo') ? 'Konec dle šablony' : 'Do konce prodeje události'"
                                                :useFallbackTo="true"
                                                :disable="!isEditable"
                                                :key="`${selectedUberEvent.id}${forEvent ? `_${selectedEvent.id}` : ''}:${selectedTicketGroup.id}`" />
                        </div>
                        <div
                            v-if="!isTemplate"
                            class="q-mt-lg"
                        >
                            <strong>Auxiliární vstupenky:</strong>
                            <NumberEdit v-model="selectedTicketGroup.auxTicketCount" :fallback="tplx('auxTicketCount') || '?'" tag="span" class="q-mr-xs" />
                            <q-btn v-if="selectedTicketGroup.auxTicketCount" round dense color="grey" size="xs" icon="insert_drive_file" @click.stop="downloadAuxTickets(false)" />
                            <q-btn v-if="selectedTicketGroup.auxTicketCount > 1" round dense color="grey" size="xs" icon="file_copy" @click.stop="downloadAuxTickets(true)" />
                            <q-btn v-if="(selectedTicketGroup.auxTicketCodes || []).length" round dense color="grey" size="xs" icon="list" @click.stop="showAuxTicketDialog = true" />
                        </div>
                        <div>
                            <strong>
                                <q-toggle v-model="(template || selectedTicketGroup).auxTicketsFree" label="Auxiliární zdarma" left-label :disable="!!template" />
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="q-mt-sm" style="margin-right: 32px" @click.stop @keyup.stop>
            <strong>Slevy:</strong>
            <FilteredSelect discounts dense use-input use-chips multiple :class="{ 'cursor-not-allowed': !!template }" :input-debounce="100"
                            :value="discounts" :filterFn="discountFilterFn" :readonly="!!template"
                            :options="discountOptions" :option-value="o => o" :option-label="o => loc(o.name)"
                            @input="discounts = $event">
                <!-- For ticket group template instances, show chips without X button -->
                <template v-if="template" v-slot:selected-item="{ opt }">
                    <q-chip dense :label="loc(opt.name)" />
                </template>
            </FilteredSelect>
        </div>

        <!-- Close -->
        <q-btn flat round dense class="absolute-top-right q-ma-sm" color="grey" icon="close" @click="selectedTicketGroup = null;" />

        <!-- External tickets dialog -->
        <q-dialog v-model="showExternalTicketDialog" @hide="onCloseExternalTickets">
            <q-card>
                <q-card-section>
                    <div class="text-h6 q-pr-xl">{{ dialogTitle }}</div>
                </q-card-section>
                <q-card-section>
                    <q-input dense filled type="textarea" v-model="externalTicketCodes" :readonly="externalTicketAction === 'list'" />
                </q-card-section>
                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="Zrušit" v-close-popup />
                    <q-btn flat label="OK" v-close-popup @click="onConfirmExternalTickets" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Aux tickets dialog -->
        <q-dialog v-if="selectedTicketGroup.auxTicketCodes" v-model="showAuxTicketDialog">
            <q-card>
                <q-card-section>
                    <div class="text-h6 q-pr-xl">Auxiliární vstupenky</div>
                </q-card-section>
                <q-card-section>
                    <q-input dense filled type="textarea" :value="selectedTicketGroup.auxTicketCodes.join('\n')" readonly />
                </q-card-section>
                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="Zavřít" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </fragment>
</template>

<script>
export default {
    props: {
        forEvent: {
            type: Boolean,
            required: true,
        },
        isTemplate: {
            type: Boolean,
            default: false,
        },
        saveFn: Function,
    },

    data() {
        return {
            externalTicketAction: null,
            externalTicketCodes: '',
            showAuxTicketDialog: null,
        };
    },

    computed: {
        isPast() {
            return dayjs(
                (this.forEvent && (this.selectedEvent.to || this.selectedEvent.from)) ||
                this.selectedUberEvent.to || this.selectedUberEvent.from,
            ).isBefore(dayjs());
        },
        isArchived() {
            return this.selectedUberEvent.archived || (this.forEvent && this.selectedEvent.archived);
        },
        isEditable() {
            return !this.isPast && !this.isArchived;
        },
        discountOptions() {
            return [
                ...this.globalDiscounts,
                ...(this.selectedUberEvent.localDiscounts || []),
            ];
        },
        template() {
            return this.tpl(this.selectedTicketGroup, this.selectedUberEvent);
        },
        discounts: {
            get() {
                return this.getDiscounts(this.selectedTicketGroup);
            },
            set(value) {
                this.selectedTicketGroup.globalDiscountRefs = value.filter(d => d.isGlobal).map(d => d.id);
                this.selectedTicketGroup.localDiscountRefs = value.filter(d => !d.isGlobal).map(d => d.id);
            },
        },
        soldInternalTicketCount() {
            return this.tgSoldInternalTicketCount(this.selectedTicketGroup);
        },
        saveBlocker() {
            return this.tgSaveBlocker(this.selectedTicketGroup, this.selectedUberEvent, this.forEvent ? this.selectedEvent : null);
        },
        sellBlocker() {
            return this.tgSellBlocker(this.selectedTicketGroup, this.selectedUberEvent, this.forEvent ? this.selectedEvent : null);
        },
        isSoldOut() {
            return this.soldInternalTicketCount === this.selectedTicketGroup.ticketCount;
        },
        isAux() {
            return this.tgIsAux(this.selectedTicketGroup, this.selectedUberEvent);
        },
        validationText() {
            return (
                this.saveBlocker ||
                (this.isAux ? (this.sellBlocker && "Kategorie je čistě auxiliární") : this.sellBlocker) ||
                (this.isSoldOut && "Kategorie vstupenek je vyprodána") ||
                "Kategorie vstupenek je připravena k prodeji"
            );
        },
        validationColor() {
            return (
                this.saveBlocker ? 'red' :
                this.sellBlocker ? (this.isAux ? 'blue' : 'orange') :
                this.isSoldOut ? 'purple' :
                'green'
            );
        },
        validationIcon() {
            return (
                this.saveBlocker ? 'warning' :
                this.sellBlocker ? (this.isAux ? 'mail' : 'warning') :
                this.isSoldOut ? 'done_all' :
                'check'
            );
        },
        ticketsForExport() {
            return this.sales
                .filter(s => s.uberEventRef === this.selectedUberEvent.id && s.status === 'paid')
                .flatMap(s => s.tickets)
                .filter(t => (
                    (this.forEvent ? t.eventRef === this.selectedEvent.id : !t.eventRef) &&
                    t.ticketGroupRef === this.selectedTicketGroup.id) &&
                    !t.annulled,
                )
                .map(t => t.code)
                .concat(this.selectedTicketGroup.externalTicketCodes)
                .concat(this.selectedTicketGroup.auxTicketCodes || []);
        },
        freeTickets() {
           return this.selectedTicketGroup.auxTicketCodes;
        },
        labelTextClass() {
            return `h6${this.selectedTicketGroup.label || this.tplx('label') ? ' italic' : ''}`;
        },
        showExternalTicketDialog: {
            get() { return !!this.externalTicketAction; },
            set(value) { this.externalTicketAction = value ? 'list' : null; },
        },
        dialogTitle() {
            switch (this.externalTicketAction) {
                case 'list': return "Externí vstupenky";
                case 'add': return "Přidat externí vstupenky";
                case 'remove': return "Odebrat externí vstupenky";
            }
        },
    },

    methods: {
        tplx(path) {
            return this.template && _.get(this.template, path);
        },
        discountFilterFn(discount, text) {
            return (
                this.loc(discount.name).toLowerCase().includes(text.toLowerCase()) &&
                !this.discounts.includes(discount)
            );
        },
        showExternalTickets() {
            this.externalTicketAction = 'list';
            this.externalTicketCodes = this.selectedTicketGroup.externalTicketCodes.join('\n');
        },
        onConfirmExternalTickets() {
            switch (this.externalTicketAction) {
                case 'add':
                    this.selectedTicketGroup.externalTicketCodes = _.uniq([
                        ...this.selectedTicketGroup.externalTicketCodes,
                        ...this.externalTicketCodes.split(/\s+/),
                    ]).sort();
                    break;

                case 'remove':
                    _.pullAll(this.selectedTicketGroup.externalTicketCodes, this.externalTicketCodes.split(/\s+/));
                    break;
            }

            this.onCloseExternalTickets();
        },
        onCloseExternalTickets() {
            this.externalTicketAction = null;
            this.externalTicketCodes = '';
        },
        async regenerateLinkToken() {
            this.$q.dialog({
                title: "Opravdu přegenerovat?",
                message: `Původní odkaz bude nenávratně zrušen a nový vygenerován při příštím uložení.`,
                cancel: true,
                ok: { label: "Ano" },
                cancel: { label: "Ne", color: null, textColor: "primary" },
            }).onOk(async () => {
                this.selectedTicketGroup.linkToken = 'new';
            });
        },
        async downloadAuxTickets(onePdfPerTicket) {
            await this.saveFn();

            const { data } = await axios.post('/aux', {
                uberEventRef: this.selectedUberEvent.id,
                eventRef: this.selectedEvent?.id,
                ticketGroupRef: this.selectedTicketGroup.id,
                onePdfPerTicket,
            }, {
                responseType: 'blob',
            });

            const type = onePdfPerTicket ? 'zip' : 'pdf';

            this.downloadBlob(`aux-tickets.${type}`, data, type);

            this.$emit('refresh');
        },
    },
};
</script>
