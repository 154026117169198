<template>
    <router-link tag="span" :to="generatePath(token)">
        <a :class="classes" :target="target">{{ text || generateUrl(token) }}</a>
    </router-link>
</template>

<script>
export default {
    props: {
        token: String,
        text: String,
        newTab: Boolean,
        invertUnderline: Boolean,
    },

    computed: {
        classes() {
            return {
                'text-primary': true,
                'invert-underline': this.invertUnderline,
            };
        },
        target() {
            return this.newTab ? '_blank' : null;
        },
    },
};
</script>

<style lang="scss" scoped>
a:hover {
    text-decoration: none;
}

a.invert-underline {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
</style>
