<template>
    <div class="card-local-discount" v-if="content" :class="{active}">
        <div class="row justify-between items-center card-local-discount__head no-wrap">
            <h3 class="typography-h3 q-ma-none">{{ content.firstName }} {{content.lastName}}</h3>
            <button @click.stop="" class="card-local-discount__trash-wrapper" @click="$emit('remove')">
                <q-icon class="card-local-discount__trash my-icon" name="svguse:/icons.svg#archive"/>
            </button>
        </div>
        <div>
            <div class="small-label">Úroveň přístupu</div>
            <div class="body-medium">{{ formattedRole }}</div>
        </div>
    </div>
    <div class="card-local-discount card-local-discount--skeleton" v-else>
        <div class="row justify-between items-center card-local-discount__head">
            <h3 class="typography-h3 q-ma-none"></h3>
            <button class="card-local-discount__trash-wrapper" @click="$emit('remove')">
                <q-icon class="card-local-discount__trash my-icon" name="svguse:/icons.svg#close"/>
            </button>
        </div>
        <div class="card-local-discount__button">
            <div class="small-label"></div>
            <div class="body-medium"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        active:{
            type: Boolean,
            default: false
        },
        content: {
            type: Object
        }
    },
    name: "CardUser",
    computed:{
        formattedRole(){
            return this.content.role === 'admin' ? 'Administrátor' : 'Prodavač'
        }
    }
}
</script>
<style lang="scss">
@import './card-local-discount'
</style>
