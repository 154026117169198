<template>
  <q-card class="sale-history no-border-radius no-box-shadow">
        <q-card-section class="q-pa-none">
            <q-list separator>
                <q-item
                    v-for="({ status, date, text, tooltip }, idx) in saleLogEntries"
                    :key="idx"
                    class="q-pa-none"
                    :class="text.startsWith('✔️') ? 'text-bold' : ''"
                >
                    <q-item-section
                        :title="tooltip"
                        class="q-item-section q-pa-none"
                    >
                        <SaleHistoryAvatar :data="status" />
                        <div class="column items-start justify-center q-px-lg">
                            <q-item-label caption class="q-mb-sm">{{ date }}</q-item-label>
                            <q-item-label class="body-medium q-my-none">{{ text }}</q-item-label>
                        </div>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
    </q-card>
</template>

<script>
export default {
    computed: {
        saleLogEntries() {
            const sale = this.selectedSale;
            const payNos = [];

            return !sale ? [] : [
                ['blocked', sale.blocked, `${this.$tc('admin.sale_log.tickets_blocked', sale.tickets.length, { price: sale.totalPrice })}`],
                ['ordered', sale.ordered, `Objednávka odeslána k platbě`],
                ..._.map(sale.payuData, ({ orderId, status, timestamp }) => [
                    status,
                    timestamp,
                    (
                        status === 'NEW' ? `PayU platba #${this.assignPayNo(payNos, orderId)} inicializována` :
                        status === 'PENDING' ? null :
                        status === 'CANCELED' ? `PayU platba #${this.assignPayNo(payNos, orderId)} odmítnuta` :
                        status === 'COMPLETED' ? `PayU platba #${this.assignPayNo(payNos, orderId)} přijata` :
                        `Neznámý status PayU platby #${this.assignPayNo(payNos, orderId)}: ${status}`
                    ),
                    `Plné ID platby PayU: ${orderId}`,
                ]),
                ['canceled', sale.canceled, `Objednávka zrušena`],
                ['expired', sale.expired, `Objednávka vypršela`],
                ['paid', sale.paid, `Vstupenky odeslány zákazníkovi`],
                ...sale.tickets.filter(t => t.annulled && t.annulled !== sale.annulled).flatMap(t => [
                    ['annulled', t.annulled, `Vstupenka ${t.code} anulována`],
                    t.refundStatus === 'FAILED' ? ['annulled', t.annulled, `Refundace vstupenky ${t.code} selhala`] : [],
                    ['refunded', t.refunded, `Vstupenka ${t.code} refundována`],
                ]),
                ['annulled', sale.annulled, `Objednávka anulována`],
                sale.refundStatus === 'FAILED' ? ['annulled', sale.annulled, `Refundace objednávky selhala`] : [],
                ['refunded', sale.refunded, `Objednávka refundována`],
            ].sort((a, b) => a[0] - b[0]).map(this.createSaleLogEntry).filter(e => e);
        },
    },
    methods: {
        createSaleLogEntry([status, timestamp, text, tooltip]) {
            return (!timestamp || !text) ? null : {
                status,
                date: dayjs(timestamp).format('DD. MM. YYYY HH:mm:ss'),
                text,
                tooltip,
            };
        },
        assignPayNo(payNos, orderId) {
            let idx = payNos.indexOf(orderId);

            if (idx !== -1) {
                return idx + 1;
            } else {
                payNos.push(orderId);

                return payNos.length;
            }
        },
    }
}
</script>

<style lang="scss">

.sale-history {
    width: 100%;
    height: 100%;
    border: $black-200 solid 1px;

    .q-item-section {
        flex-direction: row !important;
        justify-content: flex-start;
    }
}

</style>