<template>
    <form class="card-promoter-create" @submit.prevent="submitHandler">
        <div class="card-create-errors" v-if="errors.length">
            <div class="card-promoter-error tag" v-for="err in errors" :key="err.key">
                {{ err.message }}
            </div>
        </div>
        <div class="spacer" v-else></div>

        <div class="card-promoter-create__top row">
            <div class="col-6">
                <InputInlineHeading
                    :label="'Krátký název'"
                    :initial-value="content? content.title : ''" size="big"
                    @input="setTitle"
                    :error="!!errors.find(err => err.key === 'title')"
                    placeholder="Title"/>
            </div>
            <div class="col-6">
                <InputInlineHeading
                    :label="'Plný název'"
                    :initial-value="content? content.fullTitle : promoter.title" size="big"
                    @input="setFullTitle"
                    placeholder="Title"/>
            </div>
        </div>
        <div class="card-fields row">
            <q-input
                tabindex="1"
                v-model="promoter.ico"
                :error="!!errors.find(err => err.key === 'ico')"
                class="my-input my-input--square my-input--square col-6"
                :label="'IČO'"

            />
            <q-input
                tabindex="1"
                v-model="promoter.dic"
                class="my-input my-input--square my-input--square col-6"
                :label="'DIČ'"
               />
        </div>
        <div class="bottom">

            <div>
<!--                EXPORT TICKETS BTN -->
<!--                <q-btn class="my-btn my-btn&#45;&#45;secondary" outline>Kontakty <q-icon name="svguse:/icons.svg#download2x" size="20px" /></q-btn>  -->
            </div>
            <div class="flex">
                <div class="q-mr-md"><q-btn class="my-btn no-border my-btn--secondary" outline @click="$emit('close')">{{$t('close')}}</q-btn></div>

                <q-btn type="submit" style="min-width: 165px; margin-left: 8px;"
                       class="my-btn my-btn--primary"
                       :disable="disabled===true"
                       color="black"
                       :label="content? $t('update'): $t('add') "/>
            </div>
        </div>
    </form>
</template>

<script>
import InputInlineHeading from "../../InputInlineHeading/InputInlineHeading.vue";
export default {
    name: "CardGenreCreate",
    props:{
      content:{
        type: Object,
        default: null
      }
    },
    components:{
        InputInlineHeading
    },
    data:()=>({
        promoter:{
            title: null,
            fullTitle: null,
            ico: null,
            dic: null
        },
        copy: null,
        errors: [],
    }),
    computed:{
        disabled(){
            return  this.copy.title === this.promoter.title && this.copy.dic === this.promoter.dic
                    this.copy.fullTitle === this.promoter.fullTitle &&
                    this.copy.ico === this.promoter.ico
        }
    },
    methods:{
        submitHandler(){
            this.errors.length = 0;
            if(!this.promoter.title || !this.promoter.ico || !/^\d+$/.test(this.promoter.ico)) {
                if(!this.promoter.title){
                    this.errors.push({
                        key: 'title',
                        message: this.$t('validation.organizers_short-title-missing')
                    });
                }
                if(!this.promoter.ico){
                    this.errors.push({
                        key: 'ico',
                        message: this.$t('validation.organizers_ico-missing')
                    });
                }
                else if(!/^\d+$/.test(this.promoter.ico)){
                    this.errors.push({
                        key: 'ico',
                        message: this.$t('validation.organizers_ico-number')
                    });
                }
                return;
            }
            if(!this.promoter.fullTitle){
                this.promoter.fullTitle = this.promoter.title;
            }
            if (this.content) {
                this.$emit('create', {
                    original: {...this.content},
                    ...this.promoter
                })
            } else this.$emit('create', this.promoter)
            this.copy = {...this.promoter}

        },
        setTitle(title) {
            this.errors = this.errors.filter(err => err.key !== 'title');
            if(!this.promoter.fullTitle){
                this.promoter.fullTitle = title;
            }
            this.promoter.title = title
        },
        setFullTitle(title) {
            this.promoter.fullTitle = title
        },

    },
    watch:{
        promoter:{
            deep: true,
            handler(){
                if(this.promoter.dic){
                    this.errors= this.errors.filter(err => err.key !== 'dic');
                }
                if(this.promoter.ico){
                    this.errors= this.errors.filter(err => err.key !== 'ico');
                }
            }
        }
    },
    beforeMount() {
        this.copy = {...this.content}
        this.promoter.title = this.content? this.content.title: null;
        this.promoter.fullTitle = this.content? this.content.fullTitle: null;
        this.promoter.ico = this.content? this.content.ico: null;
        this.promoter.dic = this.content? this.content.dic: null;
    }
}
</script>

<style scoped lang="scss">
.card-promoter-create{
    padding: 16px 0 32px;
    border: 1px solid $black-300;
    background-color: #fff;
    position: relative;
    &__top{
        margin-top: 16px;
        padding-bottom: 40px;
        padding-left: 16px;
        padding-right: 16px;
    }
}
.bottom{
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-fields{
    margin-top: 40px;
}
.card-create-errors{
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;
}
.card-promoter-error{
    display: flex;
    align-items: center;
    padding: 4px 8px;
    color: $red-600;
    background: $red-200;

    .q-icon{
        margin-left: 4px;
    }
}
.card-promoter-error + .card-promoter-error{
    margin-left: 4px;
}
.spacer{
    height: 24px;
}

</style>
