const debugLevels = {
    none: 0,
    error: 1,
    warn: 2,
    info: 3,
};


function levelAtLeast(level) {
    return debugLevels[appConfig.debug.level] >= debugLevels[level];
}


export function info(...args) {
    levelAtLeast('info') && console.log(...args);
}

export function warn(...args) {
    levelAtLeast('warn') && console.log(...args);
}

export function error(...args) {
    levelAtLeast('error') && console.log(...args);
}
