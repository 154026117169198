<template>
    <q-select ref="select" outlined dense options-dense use-input hide-selected fill-input
              :value="value" :label="label" :input-debounce="100" :options="options"
              v-bind="$attrs" @input="$emit('input', $event)" @filter="filterFn" @keydown.native.capture.stop="onKeyDown">
        <template v-slot:before-options>
            <q-scroll-area ref="options" class="time-picker-select-scroll" style="height: 160px;">
                <q-item clickable dense v-for="(o, idx) in options" :key="o" :class="o === value ? 'text-primary' : null"
                        manual-focus :focused="focusedOptionIndex === idx"
                        @click="$refs.select.toggleOption(o)" @mouseenter.native="focusedOptionIndex = idx">
                    <q-item-section>
                        <q-item-label>{{ o }}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-scroll-area>
        </template>
        <template v-slot:option>
            <div></div>
        </template>
    </q-select>
</template>

<script>
export default {
    props: {
        value: String,
        label: String,
    },

    data() {
        return {
            options: _.flatMap(_.range(0, 24), n => [`${n}:00`, `${n}:30`]),
            focusedOptionIndex: null,
        };
    },

    methods: {
        filterFn(text, update) {
            update(() => {
                const value = text || this.value;
                const d = dayjs(value, this.timeFormat);

                // Additional regex is used to work around dayjs' non-strict parsing (e.g. 10:1 is parsed as 10:01)
                if (/[0-2]?[0-9]:[0-5][0-9]/.test(value) && d.isValid()) {
                    let sameIndex = this.options.findIndex(o => dayjs(o, this.timeFormat).isSame(d));
                    let nextIndex = this.options.findIndex(o => dayjs(o, this.timeFormat).isAfter(d));

                    if (nextIndex === -1) {
                        nextIndex = this.options.length;
                    }

                    this.scrollToIndex(nextIndex - 1);

                    this.focusedOptionIndex = (sameIndex !== -1) ? sameIndex : nextIndex - .5;

                    if (text && text !== this.value) {
                        this.$emit('input', text);
                    }
                }
            });
        },
        scrollToIndex(index) {
            setTimeout(() => {
                if (this.$refs.options) {
                    this.$refs.options.setScrollPosition(32 * (index - 2));
                }
            }, 1);
        },
        onKeyDown(ev) {
            const isUp = (ev.which === 38);
            const isDown = (ev.which === 40);

            if (isUp || isDown) {
                let index = this.focusedOptionIndex;

                // Nudge up or down (in a way that works for half-values as well)
                index = Math.round(index + (isUp ? -.6 : .6));

                // Ensure index is within bounds
                index = Math.max(0, Math.min(this.options.length - 1, index));

                // Set QSelect's internal focused option index to update input correctly
                this.$refs.select.setOptionIndex(index);
                this.$refs.select.updateInputValue(this.options[index]);

                this.scrollToIndex(index);

                this.focusedOptionIndex = index;
            }
        },
    },
};
</script>

<style lang="scss">
.time-picker-select-scroll ~ * {
    display: none;
}
</style>
