'use strict';

export default {
    app: {
        baseApiUrl: 'https://vegfactory.cz/api',
        baseWebUrl: 'https://vegfactory.cz',
    },
    storefront: {
        blockTimeoutSecs: 5 * 60,//10 * 60,
        maxTicketsPerSale: 10,
        mockHide: false,
        mockPay: false,
    },
    support: {
        email: 'sales@meetfactory.cz',
        phone: '+420 251 551 796',
    },
};

