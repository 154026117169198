<template>
    <form class="card-discount-create bg-white" @submit.prevent="submitHandler">
        <div class="card-create-errors" v-if="errors.length">
            <div class="card-discount-error tag" v-for="err in errors" :key="err.key">
                {{ err.message }}
            </div>
        </div>
        <div v-else class="spacer"></div>

        <div class="card-discount-create__top row justify-between">
            <div class="col-5">
                <InputInlineHeading
                    :label="$t('label.title_in_cs')"
                    :initial-value="content? discount.czTitle : ''"
                    @input="setCzTitle" size="big"
                    :error="!!errors.find(err => err.key === 'czTitle')"
                    placeholder="Nazev"/>
            </div>
            <div class="col-5">
                <InputInlineHeading
                    :label="$t('label.title_in_en')"
                    :initial-value="content? discount.enTitle : ''"
                    @input="setEnTitle" size="big"
                    :error="!!errors.find(err => err.key === 'enTitle')"
                    placeholder="Title"/>
            </div>
        </div>
        <div class="card-discount-create__middle">
            <q-input
                tabindex="1"
                :rules="[ val => isValidDiscount(val)|| '' ]"
                :error="!!errors.find(err => err.key === 'discount')"
                class="my-input my-input--square"
                :label="$t('label.discount')"
                v-model="discount.discount"/>
        </div>
        <div class="card-discount-create__actions row justify-end q-pr-md">
            <div class="q-mr-md"><q-btn class="my-btn no-border my-btn--secondary" outline @click="$emit('close')">{{$t('close')}}</q-btn></div>

            <q-btn type="submit" style="min-width: 165px; margin-left: 8px;"
                   class="my-btn my-btn--primary"
                   :disable="disabled===true"
                   color="black"
                   :label="content? $t('update'): $t('add') "/>
        </div>
    </form>
</template>

<script>
import InputInlineHeading from "../../InputInlineHeading/InputInlineHeading";

export default {
    name: "CardDiscountCreate",
    components: {InputInlineHeading},
    props: {
        content: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        discount: {
            czTitle: null,
            enTitle: null,
            discount: null
        },
        copy: null,
        errors: []
    }),
    watch:{
      discount: {
        handler(val){
          if(val.discount){
              this.errors = this.errors.filter(err => err.key !== 'discount')
          }
        },
        deep: true
      }
    },
    methods: {
        focusOnSecondField(){
            this.$refs.input2.focus();
        },
        setCzTitle(title) {
            this.discount.czTitle = title
            this.errors = this.errors.filter(err => err.key !== 'czTitle')
        },
        setEnTitle(title) {
            this.discount.enTitle = title
            this.errors = this.errors.filter(err => err.key !== 'enTitle')
        },
        isValidDiscount(val) {
            const reg = /^-?\d+\.?\d*$/
            return reg.test(val)
        },
        submitHandler() {
            this.errors.length = 0
            if (!this.isValidDiscount(this.discount.discount) || !this.discount.czTitle || !this.discount.enTitle) {
                if(!this.discount.czTitle){
                    this.errors.push({
                        key: 'czTitle',
                        message: this.$t('validation.discounts_cz-title-missing')
                    })
                }
                if(!this.discount.enTitle){
                    this.errors.push({
                        key: 'enTitle',
                        message: this.$t('validation.discounts_en-title-missing')
                    })
                }
                if(!this.discount.discount){
                    this.errors.push({
                        key: 'discount',
                        message: this.$t('validation.discounts_discount-missing')
                    })}
                else if(!this.isValidDiscount(this.discount.discount)){
                    this.errors.push({
                        key: 'discount',
                        message: this.$t('validation.discounts_discount-number')
                    })
                }
                return
            }
            if (this.content) {
                this.$emit('create', {
                    original: {...this.content},
                    ...this.discount
                })
            } else this.$emit('create', this.discount)
            this.copy = {...this.discount}
        }
    },
    computed:{
        disabled(){
            return  this.copy.czTitle === this.discount.czTitle
                && this.copy.enTitle === this.discount.enTitle
                && this.copy.discount === this.discount.discount


        }
    },
    beforeMount() {
        if (this.content) {
            this.discount.discount = ''+Math.round((1 - this.content.multiplier) * 100)
            this.discount.czTitle = this.content.name.cs
            this.discount.enTitle = this.content.name.en
        }
        this.copy = {...this.discount}
    }
}
</script>

<style scoped lang="scss">

.card-discount-create {
    padding: 16px 0 32px;
    border: 1px solid $black-300;
    &__top {
        margin-top: 16px;
        padding-bottom: 40px;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid $black-300;
    }

    &__middle {
        position: relative;
        margin-bottom: 32px;
    }
}
.card-create-errors{
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;
}
.card-discount-error{
    display: flex;
    align-items: center;
    padding: 4px 8px;
    color: $red-600;
    background: $red-200;

    .q-icon{
        margin-left: 4px;
    }
}
.card-discount-error + .card-discount-error{
    margin-left: 4px;
}
.spacer{
    height: 24px;
}

</style>
