<template>
    <q-card :class="isArchived ? 'bg-grey-4' : 'bg-green-1'">
        <q-card-section :class="`text-body2 text-${validationColor}`">
            <q-icon :name="validationIcon" :color="validationColor" />
            <span>{{ validationText }}</span>
        </q-card-section>
        <q-card-section>
            <TextEdit v-model="selectedGenre.title" fallback="⧼ Název ⧽" textClass="h5" title="Název" />
        </q-card-section>
        <q-card-section v-if="!isArchived" class="row q-gutter-md">
            <SaveButton :saveFn="save" :disable="saveBlocker" />
            <DeleteButton v-if="selectedGenre.id" :deleteFn="del" type="genre" :item="selectedGenre" />
            <q-btn v-else label="Zrušit" @click="selectedGenre = null" />
            <q-space />
            <q-btn outline color="primary" label="Exportovat kontakty" @click="exportContacts" />
        </q-card-section>
        <q-btn flat round dense class="absolute-top-right q-ma-sm" color="grey" icon="close" @click="selectedGenre = null;" />
    </q-card>
</template>

<script>
export default {
    computed: {
        isArchived() {
            return this.selectedGenre.archived;
        },
        saveBlocker() {
            return (
                (this.isArchived && "Žánr byl archivován") ||
                (!this.selectedGenre.title && "Chybí název")
            );
        },
        validationText() {
            return this.saveBlocker || "Žánr je plně validní";
        },
        validationColor() {
            return this.isArchived ? 'blue-grey-5' : this.saveBlocker ? 'red' : 'green';
        },
        validationIcon() {
            return this.isArchived ? 'close' : this.saveBlocker ? 'warning' : 'check';
        },
    },

    methods: {
        exportContacts() {
            const ueIds = this.uberEvents.filter(ue => ue.genreRefs.includes(this.selectedGenre.id)).map(ue => ue.id);
            const sales = this.sales.filter(s => ueIds.includes(s.uberEventRef));

            let contacts = {
                '*': [
                    'E-mail',
                    'Jméno',
                    'Příjmení',
                ],
            };

            for (const sale of sales) {
                // Overwrite duplicates with newer data
                contacts[sale.contactInfo.email] = [
                    sale.contactInfo.email,
                    sale.contactInfo.firstName,
                    sale.contactInfo.lastName,
                ];
            }

            const text = Object.values(contacts).map(c => c.join(',')).sort().join('\r\n');

            const downloadFileName = `export-${this.selectedGenre.title.toLowerCase()}.txt`;

            this.downloadTextFile(downloadFileName, text);
        },
        async save() {
            if (this.selectedGenre.id) {
                const genre = await this.update('genres', this.selectedGenre);

                this.updateIn(this.genres, genre);
                this.selectedGenre = genre;
            } else {
                const genre = await this.create('genres', this.selectedGenre);

                this.genres.push(genre);
                this.selectedGenre = genre;
            }

            notifyOk("Žánr byl uložen.");
        },
        async del() {
            const { result, archived } = await this.delete('genres', this.selectedGenre);

            if (result === 'destroyed') {
                notifyOk("Žánr byl smazán.");

                this.deleteIn(this.genres, this.selectedGenre);
            } else if (result === 'archived') {
                notifyOk("Žánr byl archivován.");

                this.selectedGenre.archived = archived;

                this.updateIn(this.genres, this.selectedGenre);
            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }

            this.selectedGenre = null;
        },
    },
};
</script>
