import { render, staticRenderFns } from "./SaleHistoryAvatar.vue?vue&type=template&id=474f6315&scoped=true&"
import script from "./SaleHistoryAvatar.vue?vue&type=script&lang=js&"
export * from "./SaleHistoryAvatar.vue?vue&type=script&lang=js&"
import style0 from "./SaleHistoryAvatar.vue?vue&type=style&index=0&id=474f6315&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474f6315",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon});
