<template>
    <q-chip class="my-chip" dense :style="`background:${color}`" text-color="white" :label="label" />
</template>

<script>
export default {
    props: {
        data: Object,
    },

    computed: {
        color() {
            switch (this.data.type) {
                case 'koncert': return '#10B981';
                case 'multikoncert': return '#105EB9';
                case 'festival': return '#7B61FF';
                case 'divadlo': return '#FC9821';
                case 'vystava': return '#C92614';
                case 'ostatni': return '#1C9CF8';
            }
        },
        label() {
            return this.$tc(`event.type.${this.data.type}`, 1);
        },
    },
};
</script>
