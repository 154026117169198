<template>
    <component :is="tag" :class="[textClasses, 'cursor-pointer']" @click.stop>
        <span v-if="value && type === 'password'">{{ value.replace(/./g, '•') }}</span>
        <span v-else-if="value">{{ value }}{{ suffix }}</span>
        <span v-else class="text-grey">{{ fallback || '...' }}{{ suffix }}</span>
        <PopupEdit :value="value" anchor="center left" @input="$emit('input', $event)" @save="$emit('save', $event)" @cancel="$emit('cancel', $event)">
            <q-input :value="value" :type="type" :suffix="suffix" dense autofocus @input="$emit('input', $event)" />
        </PopupEdit>
    </component>
</template>

<script>
export default {
    props: {
        value: String,
        fallback: String,
        textClass: String,
        suffix: String,
        type: {
            type: String,
            default: 'text',
        },
        tag: {
            type: String,
            default: 'div',
        },
    },

    computed: {
        textClasses() {
            return this.textClass ? this.textClass.split(' ').map(c => `text-${c}`).join(' ') : null;
        },
    },
};
</script>
