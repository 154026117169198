<template>
    <q-popup-edit :value="value" v-bind="$attrs" @show="onShow" @hide="onHide"
                  @input="$emit('input', $event)" @save="$emit('save', $event)" @cancel="$emit('cancel', $event)">
        <div @keydown.capture="blockEvent" @keyup.capture="blockEvent" @keypress.capture="blockEvent">
            <slot />
        </div>
    </q-popup-edit>
</template>

<script>
export default {
    props: {
        value: true,
    },

    data() {
        return {
            isOpen: false,
        };
    },

    methods: {
        blockEvent(ev) {
            if (!this.isOpen) {
                ev.preventDefault();
                ev.stopPropagation();
            }
        },
        onShow(ev) {
            this.isOpen = true;
            this.$emit('show', ev);
        },
        onHide(ev) {
            this.isOpen = false;
            this.$emit('hide', ev);
        },
    },
};
</script>
