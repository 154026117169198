<template>
    <PopupEdit v-model="popupModel" :disable="disable" content-style="padding: 4px; border-radius: 8px;"
               buttons label-set="OK" label-cancel="Zrušit" :cover="false" anchor="top left" self="bottom left"
               @show="onShow">
        <!-- One day -->
        <DateRangePicker v-if="oneDay" v-model="fromTo" :futureOnly="futureOnly" />
        <!-- Both fallbacks checked / dates unspecified -->
        <DateRangePicker v-else-if="useFallbackFrom_ && useFallbackTo_" disable v-model="range" :futureOnly="futureOnly" />
        <!-- From missing fallback / no date to ... -->
        <DateRangePicker v-else-if="useFallbackFrom_ && !fallbackFrom" v-model="to_" :futureOnly="futureOnly" />
        <!-- From fallback to ... -->
        <DateRangePicker v-else-if="useFallbackFrom_ && fallbackFrom" v-model="to_" :extra="from && to && range" :futureOnly="futureOnly" />
        <!-- From ... to missing fallback / no date -->
        <DateRangePicker v-else-if="useFallbackTo_ && !fallbackTo" v-model="from_" :futureOnly="futureOnly" />
        <!-- From ... to fallback -->
        <DateRangePicker v-else-if="useFallbackTo_ && fallbackTo" v-model="from_" :extra="from && to && range" :futureOnly="futureOnly" />
        <!-- From ... to ... -->
        <DateRangePicker v-else v-model="range" :extra="(!!from === !!to) ? null : toJsDate(from || to)" :futureOnly="futureOnly" />

        <div v-if="showTime" class="q-mt-xs">
            <div class="row q-col-gutter-xs date-range-picker-time">
                <TimePicker class="col" v-model="timeFrom" label="Od" :disable="!from" />
                <q-checkbox dense v-model="timeToEnabled" :disable="!to" />
                <TimePicker class="col" v-model="timeTo" label="Do" :disable="!to || !timeToEnabled" />
            </div>
        </div>

        <div v-if="fallbackFromLabel || fallbackToLabel" class="q-mt-xs q-pa-xs">
            <div v-if="fallbackFromLabel" class="q-pa-xs">
                <q-checkbox dense v-model="useFallbackFrom_">
                    <span class="q-pl-xs">{{ fallbackFromLabel }}</span>
                </q-checkbox>
            </div>
            <div v-if="fallbackToLabel" class="q-pa-xs">
                <q-checkbox dense v-model="useFallbackTo_">
                    <span class="q-pl-xs">{{ fallbackToLabel }}</span>
                </q-checkbox>
            </div>
        </div>
    </PopupEdit>
</template>

<script>
export default {
    props: {
        from: String,
        to: String,
        fallbackFrom: String,
        fallbackTo: String,
        fallbackFromLabel: String,
        fallbackToLabel: String,
        useFallbackFrom: Boolean,
        useFallbackTo: Boolean,
        defaultFrom: String,
        defaultTo: String,
        showTime: Boolean,
        defaultTime: String,
        oneDay: Boolean,
        disable: Boolean,
        futureOnly: Boolean,
    },

    data() {
        return {
            timeFrom_: null,
            timeTo_: null,
            useFallbackFrom_: !this.from && this.useFallbackFrom,
            useFallbackTo_: !this.to && this.useFallbackTo,
            timeToEnabled: (!this.to && !this.oneDay) || this.to !== this.from,
        };
    },

    computed: {
        tryFrom() {
            return this.from || this.fallbackFrom;
        },
        tryTo() {
            return this.to || this.fallbackTo;
        },
        from_: {
            get() {
                return this.tryFrom && this.toJsDate(this.tryFrom);
            },
            set(value) {
                this.$emit('update:from', value && dayjs(this.updateTime(value, this.timeFrom_)).format());
            },
        },
        to_: {
            get() {
                return this.tryTo && this.toJsDate(this.tryTo);
            },
            set(value) {
                this.$emit('update:to', value && dayjs(this.updateTime(value, this.timeTo_)).format());
            },
        },
        fromTo: {
            get() {
                return this.from_;
            },
            set(value) {
                this.from_ = value;
                this.to_ = value;
            },
        },
        range: {
            get() {
                return {
                    start: this.from_,
                    end: this.to_,
                };
            },
            set(value) {
                if (value) {
                    this.from_ = this.useFallbackFrom_ ? null : value.start;
                    this.to_ = this.useFallbackTo_ ? null : value.end;
                }
            },
        },
        timeFrom: {
            get() {
                return this.getTime(this.tryFrom);
            },
            set(value) {
                this.timeFrom_ = value;
                this.$emit('update:from', this.updateTime(this.from, value));

                if (value && !this.timeToEnabled && this.timeTo !== value) {
                    this.$nextTick(() => {
                        this.timeTo = value;
                    });
                }

                if (value && this.isSameDay() && this.timeTo < value) {
                    this.$nextTick(() => {
                        this.timeTo = value;
                    });
                }
            },
        },
        timeTo: {
            get() {
                return this.getTime(this.tryTo);
            },
            set(value) {
                this.timeTo_ = value;
                this.$emit('update:to', this.updateTime(this.to, value));

                if (value && this.isSameDay() && this.timeFrom > value) {
                    this.$nextTick(() => {
                        this.timeFrom = value;
                    });
                }
            },
        },
        popupModel: {
            get() {
                return {
                    from: this.from,
                    to: this.to,
                    timeFrom: this.timeFrom_,
                    timeTo: this.timeTo_,
                    uff: this.useFallbackFrom_,
                    uft: this.useFallbackTo_,
                };
            },
            set({ from, to, timeFrom, timeTo, uff, uft }) {
                this.$emit('update:from', from);
                this.$emit('update:to', to);

                this.timeFrom_ = timeFrom;
                this.timeTo_ = timeTo;
                this.useFallbackFrom_ = uff;
                this.useFallbackTo_ = uft;
            },
        },
    },

    watch: {
        // Toggle dates immediately after fallback checkbox click
        useFallbackFrom_() {
            this.range = this.range;
        },
        useFallbackTo_() {
            this.range = this.range;
        },
        timeToEnabled() {
            this.timeTo = this.timeFrom;
        },
    },

    methods: {
        toJsDate(dateTime) {
            return dayjs(dateTime).toDate();
        },
        getTime(dateTime) {
            return dateTime && dayjs(dateTime).format(this.timeFormat);
        },
        updateTime(dateTime, time) {
            const d = dayjs(time || this.defaultTime, this.timeFormat);

            if (!dateTime || !d.isValid())
                return dateTime;

            return dayjs(dateTime).hour(d.hour()).minute(d.minute()).format();
        },
        isSameDay() {
            return dayjs(this.from_).isSame(dayjs(this.to_), 'day');
        },
        onShow() {
            if (this.defaultFrom && !this.from_) {
                this.from_ = this.defaultFrom;
            }

            if (this.defaultTo && !this.from_) {
                this.from_ = this.defaultTo;
            }
        },
    },

    mounted() {
        this.timeFrom_ = this.getTime(this.tryFrom);
        this.timeTo_ = this.getTime(this.tryTo);
    },
};
</script>

<style lang="scss">
.date-range-picker-time .q-select input {
    width: 0;
}
</style>
