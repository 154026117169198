<template>
    <form class="card-user-create" @submit.prevent="submitHandler">
        <div class="card-create-errors" v-if="errors.length">
            <div class="card-user-error tag" v-for="err in errors" :key="err.key">
                {{ err.message }}
            </div>
        </div>
        <div class="spacer" v-else></div>

        <div class="card-user-create__top row">
            <div class="col-6">
                <q-input
                    tabindex="1"
                    v-model="user.firstName"
                    class="my-input my-input--square my-input--square full-width"
                    :label="'Jméno'"
                    :error="!!errors.find(err => err.key === 'name')"
                />
            </div>
            <div class="col-6">
                <q-input
                    tabindex="1"
                    v-model="user.lastName"
                    class="my-input my-input--square my-input--square full-width"
                    :label="'Příjmení'"
                    :error="!!errors.find(err => err.key === 'lastname')"
                />
            </div>
        </div>
        <div class="card-fields row">
            <q-input
                tabindex="1"
                v-model="user.email"
                class="my-input my-input--square my-input--square col-6"
                :label="'E-mail'"
                :error="!!errors.find(err => err.key === 'email')"
            />
            <q-input
                tabindex="1"
                v-model="user.password"
                type="password"
                class="my-input my-input--square my-input--square col-6"
                :label="'Heslo'"
                :error="!!errors.find(err => err.key === 'password')"
            />
        </div>

        <div class="card-fields row">
            <q-select
                tabindex="1"
                v-model="user.role"
                class="my-input-select my-input--square col-6"
                dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                popup-content-class="my-input-select__options"
                :options="[{ value: 'admin', label: 'Administrátor' }, { value: 'seller', label: 'Prodavač' }]"
                :label="'Úroveň přístupu'"
                :error="!!errors.find(err => err.key === 'role')"
            />
            <q-input
                tabindex="1"
                editable="false"
                readonly
                :disable="!formattedLastLogin"
                :value="formattedLastLogin"
                class="my-input my-input--square col-6"
                :label="'Poslední přihlášení'"
            />
        </div>
        <div class="bottom">
            <div>
            </div>
            <div class="flex">
                <div class="q-mr-md"><q-btn class="my-btn no-border my-btn--secondary" outline @click="$emit('close')">{{$t('close')}}</q-btn></div>

                <q-btn type="submit" style="min-width: 165px; margin-left: 8px;"
                       class="my-btn my-btn--primary"
                       :disable="disabled===true"
                       color="black"
                       :label="content? $t('update'): $t('add') "/>
            </div>
        </div>
    </form>
</template>

<script>
import  {date} from 'quasar'
export default {
    name: "CardUserCreate",
    props:{
        content:{
            type: Object,
            default: null
        }
    },
    data:()=>({
        user:{
            firstName: null,
            lastName: null,
            email: null,
            role: null,
            password: null,
            lastLogin: null,
        },
        copy: null,
        errors: [],
    }),
    computed:{
        disabled(){
            return  this.copy.firstName === this.user.firstName && this.copy.role === this.user.role.value
            && this.copy.lastName === this.user.lastName && this.copy.email === this.user.email
            && !this.user.password
        },
        formattedLastLogin(){
            if(!this.user.lastLogin) return ''
            return date.formatDate(this.user.lastLogin, 'DD.MM.YYYY HH:mm')
        }
    },
    methods:{
        validate(){
            this.errors = []
            if(!this.user.firstName){
                this.errors.push({
                    key: 'name',
                    message: this.$t('validation.required-name')
                })
            }
            if(!this.user.lastName){
                this.errors.push({
                    key: 'lastname',
                    message: this.$t('validation.required-lastname')
                })
            }
            if(!this.user.email || !this.isEmail(this.user.email)){
                this.errors.push({
                    key: 'email',
                    message: this.$t('validation.valid-email')
                })
            }

            if(this.user.password && this.user.password.length < 6) {
                this.errors.push({
                    key: 'password',
                    message: this.$t('validation.password')
                })
            }
            if(!this.user.role){
                this.errors.push({
                    key: 'role',
                    message: this.$t('validation.required-role')
                })
            }
        },
        submitHandler(){
            this.errors.length = 0;
            this.validate()
            if(this.errors.length > 0) return
            if (this.content) {
                const obj = {
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    email: this.user.email,
                    role: this.user.role.value,
                };
                if(this.user.password){
                    obj.password = this.user.password;
                }
                this.$emit('create', {
                    original: {...this.content},
                    ...obj,
                    role: this.user.role.value,
                })
            } else this.$emit('create', this.user)
            this.copy = {...this.content}
            this.user.password = null;
        },
        setTitle(title) {
            this.errors = this.errors.filter(err => err.key !== 'title');
            if(!this.user.lastName){
                this.user.lastName = title;
            }
            this.user.firstName = title
        },
        setFullTitle(title) {
            this.user.lastName = title
        },

    },
    beforeMount() {
        this.copy = {...this.content}
        this.user.firstName = this.content? this.content.firstName: null;
        this.user.lastName = this.content? this.content.lastName: null;
        this.user.email = this.content? this.content.email: null;
        this.user.lastLogin = this.content? this.content.lastLogin: null;
        this.user.password = null;
        this.user.role = this.content? {
            label: this.content.role === 'admin'? 'Administrátor': 'Prodavač',
            value: this.content.role
        }: null;
    }
}
</script>

<style scoped lang="scss">
.card-user-create{
    padding: 16px 0 32px;
    border: 1px solid $black-300;
    background-color: #fff;
    position: relative;
    &__top{
        margin-top: 16px;
    }
}
.bottom{
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-create-errors{
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;
}
.card-user-error{
    display: flex;
    align-items: center;
    padding: 4px 8px;
    color: $red-600;
    background: $red-200;

    .q-icon{
        margin-left: 4px;
    }
}
.card-user-error + .card-user-error{
    margin-left: 4px;
}
.spacer{
    height: 24px;
}

</style>
