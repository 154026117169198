<template>
    <component :is="tag">
        {{ formatPercentage(row) }}
        <PopupEdit :value="getPercentage(row)" :validate="v => v >= -100"
                   @input="setPercentage(row, $event)" @save="$emit('save', $event)" @cancel="$emit('cancel', $event)">
            <q-input type="number" :value="getPercentage(row)" @input="setPercentage(row, $event)" dense autofocus />
        </PopupEdit>
    </component>
</template>

<script>
export default {
    props: {
        row: {
            type: Object,
            required: true,
        },
        tag: {
            type: String,
            default: 'div',
        },
    },

    methods: {
        getPercentage(row) {
            return row.multiplier != null ? Math.round((row.multiplier - 1) * 100) : null;
        },
        setPercentage(row, value) {
            row.multiplier = parseInt(value) ? (1 + value / 100) : null;

            this.$emit('input', value);
        },
        formatPercentage(row) {
            const percentage = this.getPercentage(row);
            const sign = (percentage > 0) ? '+' : '';

            return row.multiplier != null ? `${sign}${percentage}%` : '-';
        },
    },
};
</script>
