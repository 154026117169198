<template>
    <span :class="cls">{{ value ? format(value) : fallback }}</span>
</template>

<script>
export default {
    props: {
        field: String,
        format: {
            type: Function,
            default: v => v,
        },
        fallback: {
            type: String,
            default: '?',
        },
        values: Array,
    },

    computed: {
        value() { return this.get(this.values.find(v => this.get(v))); },
        cls() { return !this.get(this.values[0]) && this.value ? 'text-grey' : null; },
    },

    methods: {
        get(v) { return this.field ? (v ? v[this.field] : null) : v; },
    },
};
</script>
