<template>
    <form class="card-genre-create" @submit.prevent="submitHandler">
        <div class="card-create-errors" v-if="errors.length">
            <div class="card-discount-error tag" v-for="err in errors" :key="err.key">
                {{ err.message }}
            </div>
        </div>
        <div v-else class="spacer"></div>
        <div class="card-genre-create__top">
            <InputInlineHeading
                :label="$t('label.title')"
                :initial-value="content? content.title : ''" size="big"
                @input="setTitle"
                :error="!!errors.find(err => err.key === 'title')"
                placeholder="Title"/>
        </div>
        <div class="bottom">
            <div>
<!--                <q-btn class="my-btn my-btn&#45;&#45;secondary" outline>Kontakty <q-icon name="svguse:/icons.svg#download2x" size="20px" /></q-btn>-->
            </div>
            <div class="flex">
                <div class="q-mr-md"><q-btn class="my-btn no-border my-btn--secondary" outline @click="$emit('close')">{{$t('close')}}</q-btn></div>

                <q-btn type="submit" style="min-width: 165px; margin-left: 8px;"
                       class="my-btn my-btn--primary"
                       :disable="disabled===true"
                       color="black"
                       :label="content? $t('update'): $t('add') "/>
            </div>
        </div>
    </form>
</template>

<script>
import InputInlineHeading from "../../InputInlineHeading/InputInlineHeading.vue";
export default {
    name: "CardGenreCreate",
    props:{
      content:{
        type: Object,
        default: null
      }
    },
    components:{
        InputInlineHeading
    },
    data:()=>({
        genre:{
            title: null,
        },
        copy: null,
        errors: [],
    }),
    computed:{
        disabled(){
            return  this.copy.title === this.genre.title || !this.genre.title;
        }
    },
    methods:{
        submitHandler(){
            if(!this.genre.title || !this.genre.title.trim().length){
                this.errors.push({
                    key: 'title',
                    message: this.$t('validation.genres_title_missing')
                })
                return;
            }
            if (this.content) {
                this.$emit('create', {
                    original: {...this.content},
                    ...this.genre
                })
            } else this.$emit('create', this.genre)
            this.copy = {...this.genre}
            this.errorMessage = false
        },
        setTitle(title) {
            this.errors = this.errors.filter(err => err.key !== 'title')
            this.genre.title = title
        },

    },
    beforeMount() {
        if (this.content) {
            this.genre.title = this.content.title
        }
        this.copy = {...this.content}
    }
}
</script>

<style scoped lang="scss">
.card-genre-create{
    padding: 16px 0 32px;
    border: 1px solid $black-300;
    background-color: #fff;
    position: relative;
    &__top {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }

}
.bottom{
    margin-top: 44px;
    padding-top: 32px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $black-300;
    }
}
.card-discount-error{
    display: flex;
    align-items: center;
    padding: 4px 8px;
    color: $red-600;
    background: $red-200;
    .q-icon{
        margin-left: 4px;
    }
}
.card-create-errors{
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;
}
.bottom{
    padding-left: 16px;
    padding-right: 16px;
}
.spacer{
    height: 24px;
}
</style>
