<template>
    <q-card :class="isArchived ? 'bg-grey-4' : 'bg-red-1'">
        <!-- Validation info -->
        <q-card-section>
            <div :class="`text-body2 text-${validationColor}`">
                <q-icon :name="validationIcon" :color="validationColor" />
                <span>{{ validationText }}</span>
            </div>
            <div v-if="!saveBlocker && someTGSellBlocker" :class="`text-body2 text-orange`">
                <q-icon name="warning" color="orange" />
                <span>Některé z kategorií vstupenek nejsou připraveny k prodeji</span>
            </div>
        </q-card-section>

        <!-- Primary data -->
        <q-card-section>
            <div class="row q-col-gutter-md">
                <div class="col-6">
                    <div v-if="sueIs('multikoncert', 'divadlo')" class="text-h5">{{ formatDate(selectedEvent.from) }}</div>
                    <LangEdit code="cs" :values="selectedEvent.name" fallback="⧼ Název CZ ⧽" :iconSize="24" :textClass="sueIs('multikoncert', 'divadlo') ? 'h6' : 'h5'" />
                    <LangEdit code="en" :values="selectedEvent.name" fallback="⧼ Název EN ⧽" :iconSize="24" textClass="h6" />
                    <q-select
                        v-model="selectedEvent.venueRef"
                        popup-content-class="my-input-select__option"
                        class="my-input-select venues"
                        dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                        input-debounce="0"
                        behavior="menu"
                        outlined
                        emit-value
                        map-options
                        label="Vybrat lokaci"
                        :display-value="selectedEvent.venueRef ? findById(venues, selectedEvent.venueRef).name.cs : null"
                        :options="venues"
                        :option-label="o => loc(o.name)"
                        @input="handleVenue"
                    />
                    <div>
                        {{ formatDateRange(selectedEvent.from, selectedEvent.to, true) }}
                        <DateTimeRangePopup oneDay showTime futureOnly
                                            :defaultTime="defaultEventTime"
                                            :from.sync="selectedEvent.from"
                                            :to.sync="selectedEvent.to"
                                            :disable="!isEditable"
                                            :key="`${selectedUberEvent.id}_${selectedEvent.id}`" />
                    </div>
                </div>
                <div class="col-6">
                    <div><strong><q-toggle v-model="selectedEvent.isListed" label="Viditelné" left-label /></strong></div>
                    <div class="q-pb-sm">
                        <strong>Odkaz:</strong>
                        <span v-if="!selectedEvent.linkToken" class="text-italic">bude vygenerován při uložení</span>
                        <span v-else-if="selectedEvent.linkToken === 'new'" class="text-italic">...</span>
                        <TokenLink v-else :token="selectedEvent.linkToken" newTab />
                        <q-btn v-if="isEditable" dense color="primary" label="Přegenerovat" class="q-ml-xs q-px-sm q-py-none" @click="regenerateLinkToken" />
                    </div>
                    <div>
                        <strong>Předprodej:</strong>
                        <FallbackCascade :format="formatDate" :values="[
                            selectedEvent.publicFrom,
                            eFallbackPublicFrom(selectedUberEvent, selectedEvent),
                        ]" />
                        <span> – </span>
                        <FallbackCascade :format="formatDate" :values="[
                            selectedEvent.publicTo,
                            eFallbackPublicTo(selectedUberEvent, selectedEvent),
                        ]" />
                        <DateTimeRangePopup showTime
                                            :from.sync="selectedEvent.publicFrom"
                                            :to.sync="selectedEvent.publicTo"
                                            :fallbackFrom="eFallbackPublicFrom(selectedUberEvent, selectedEvent)"
                                            fallbackFromLabel="Zdědit začátek předprodeje"
                                            :useFallbackFrom="false"
                                            :defaultFrom="tomorrow()"
                                            :fallbackTo="eFallbackPublicTo(selectedUberEvent, selectedEvent)"
                                            :fallbackToLabel="(sueIs('multikoncert', 'divadlo', 'vystava') && selectedUberEvent.publicTo) ?
                                                                'Zdědit konec předprodeje' :
                                                                'Do začátku události'"
                                            :useFallbackTo="true"
                                            :disable="!isEditable"
                                            :key="`${selectedUberEvent.id}_${selectedEvent.id}`" />
                    </div>
                </div>
            </div>
        </q-card-section>

        <!-- Ticket groups -->
        <q-card-section class="row items-center q-col-gutter-lg">
            <div class="col-grow text-h6">Kategorie vstupenek:</div>
            <div><q-btn outline color="primary" label="Exportovat CSV" @click="exportCodesCSV(null, ticketsForExport, true, false, freeTickets)" /></div>
            <div><q-btn outline color="primary" label="Exportovat kódy pro podudálost" @click="exportCodes(null, ticketsForExport, true)" /></div>
            <div><q-btn round dense color="primary" icon="add" @click="newTicketGroup" /></div>
        </q-card-section>
        <TicketGroupList :ticketGroups="selectedEvent.ticketGroups" :forEvent="true" :saveFn="save" @refresh="$emit('refresh')" />

        <!-- Save, delete -->
        <q-card-section v-if="!isArchived" class="row q-gutter-sm">
            <SaveButton v-if="!isPast" :saveFn="save" :disable="saveBlocker" />
            <DeleteButton v-if="selectedEvent.id" :deleteFn="del" type="event" :item="selectedEvent" :useArchiveLabel="isPast" />
            <q-btn v-else label="Zrušit" @click="selectedEvent = null" />
            <q-space />
            <q-btn v-if="selectedEvent.id" flat round dense color="grey" icon="refresh" @click="$emit('refresh')" />
        </q-card-section>

        <!-- Close -->
        <q-btn flat round dense class="absolute-top-right q-ma-sm" color="grey" icon="close" @click="selectedEvent = null;" />
    </q-card>
</template>

<script>
export default {
    computed: {
        isPast() {
            return dayjs(this.selectedEvent.to || this.selectedEvent.from).isBefore(dayjs());
        },
        isArchived() {
            return this.selectedEvent.archived || this.selectedUberEvent.archived;
        },
        isEditable() {
            return !this.isPast && !this.isArchived;
        },
        saveBlocker() {
            return this.eSaveBlocker(this.selectedEvent, this.selectedUberEvent);
        },
        sellBlocker() {
            return this.eSellBlocker(this.selectedEvent, this.selectedUberEvent);
        },
        someTGSellBlocker() {
            return this.eSomeTGSellBlocker(this.selectedEvent, this.selectedUberEvent);
        },
        allTgsAux() {
            return this.eAllTgsAux(this.selectedEvent, this.selectedUberEvent);
        },
        validationText() {
            return (
                this.saveBlocker ||
                (this.allTgsAux && "Podudálost je čistě wholesale") ||
                this.sellBlocker ||
                (this.isPast && "Podudálost již proběhla") ||
                "Podudálost je připravena k prodeji"
            );
        },
        validationColor() {
            return (
                (this.isPast || this.isArchived) ? 'blue-grey-5' :
                this.saveBlocker ? 'red' :
                this.allTgsAux ? 'blue' :
                this.sellBlocker ? 'orange' :
                'green'
            );
        },
        validationIcon() {
            return (
                this.isArchived ? 'close' :
                this.saveBlocker ? 'warning' :
                this.allTgsAux ? 'mail' :
                this.sellBlocker ? 'warning' :
                'check'
            );
        },
        ticketsForExport() {
            return this.sales
                .filter(s => s.uberEventRef === this.selectedUberEvent.id && s.paid !== null)
                .flatMap(s => s.tickets)
                .filter(t => t.eventRef === this.selectedEvent.id && !t.annulled)
                .map(t => t.code)
        },
        freeTickets() {
            return  this.selectedEvent.ticketGroups.flatMap(tg => [
                    ...(tg.auxTicketCodes || []),
            ]);
        },
    },

    methods: {
        handleVenue(venue) {
            this.selectedEvent.venueRef = venue.id
            // console.log(this.venues.filter(v => v.name.cs === venue).id)
        },
        async regenerateLinkToken() {
            this.$q.dialog({
                title: "Opravdu přegenerovat?",
                message: `Podudálost bude uložena. Původní odkaz bude nenávratně zrušen.`,
                cancel: true,
                ok: { label: "Ano" },
                cancel: { label: "Ne", color: null, textColor: "primary" },
            }).onOk(async () => {
                this.selectedEvent.linkToken = 'new';

                await this.save();
            });
        },
        // Save event including ticket groups
        async save() {
            let event;

            if (this.selectedEvent.id) {
                // Update uber-event with updated event

                if (!this.selectedUberEvent.id) {
                    // If uber-event hasn't been saved yet, update only locally
                    event = this.selectedEvent;
                } else {
                    // Otherwise, update via API
                    const uberEvent = await this.update('uberEvents', {
                        id: this.selectedUberEvent.id,
                        events: this.updateIn([...this.selectedUberEvent.events], this.selectedEvent),
                    });

                    event = this.findIn(uberEvent.events, this.selectedEvent);

                    this.selectedUberEvent.updated = uberEvent.updated;
                }

                this.updateIn(this.selectedUberEvent.events, event);
            } else {
                // Update uber-event with new event

                const max = _.maxBy(this.selectedUberEvent.events, 'id');

                this.selectedEvent.id = max ? max.id + 1 : 1;

                if (!this.selectedUberEvent.id) {
                    // If uber-event hasn't been saved yet, update only locally
                    event = this.selectedEvent;
                } else {
                    // Otherwise, update via API
                    const uberEvent = await this.update('uberEvents', {
                        id: this.selectedUberEvent.id,
                        events: [...this.selectedUberEvent.events, this.selectedEvent],
                    });

                    event = this.findIn(uberEvent.events, this.selectedEvent);

                    this.selectedUberEvent.updated = uberEvent.updated;
                }

                this.selectedUberEvent.events.push(event);
            }

            if (this.selectedUberEvent.id) {
                this.updateIn(this.uberEvents, this.selectedUberEvent);
                this.selectedUberEvent = this.selectedUberEvent;

                notifyOk("Podudálost byla uložena.");
            } else {
                // Note: Don't show notification having saved only locally and not via API
            }

            this.selectedEvent = event;

            // Note: May select wrong group; fixing this is optional in provisional UI
            this.selectedTicketGroup = this.selectedTicketGroup && this.findIn(this.selectedEvent.ticketGroups, this.selectedTicketGroup);
        },
        async del() {
            let event;

            // Do not delete event via API if uber-event hasn't been saved yet
            if (this.selectedUberEvent.id) {
                const uberEvent = await this.update('uberEvents', {
                    id: this.selectedUberEvent.id,
                    events: this.deleteIn([...this.selectedUberEvent.events], this.selectedEvent),
                });

                event = this.findIn(uberEvent.events, this.selectedEvent);

                this.selectedUberEvent.updated = uberEvent.updated;
            }

            if (event) {
                if (event.archived) {
                    notifyOk("Podudálost byla archivována.");

                    this.updateIn(this.selectedUberEvent.events, event);
                } else {
                    debug.warn("Unexpected deletion no-op.");
                }
            } else {
                notifyOk("Podudálost byla smazána.");

                this.deleteIn(this.selectedUberEvent.events, this.selectedEvent);
            }

            if (this.selectedUberEvent.id) {
                this.updateIn(this.uberEvents, this.selectedUberEvent);
                this.selectedUberEvent = this.selectedUberEvent;
            }

            this.selectedEvent = null;
        },
        newTicketGroup() {
            const id = this.nextId(this.selectedEvent.ticketGroups);
            const ticketGroup = this.getNewTicketGroup(id);

            this.selectedEvent.ticketGroups.push(ticketGroup);
            this.selectedTicketGroup = ticketGroup;
        },
    },
};
</script>

