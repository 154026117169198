import Vue from 'vue';

import Calendar from 'v-calendar';
import Constants from 'vue-constants';
import Fragment from 'vue-fragment';

import i18n from '@/libs/i18n';
import store from '@/libs/store';
import router from '@/libs/router';

import App from '@/App.vue';
import Helpers from '@/mixins/Helpers.vue';

import '@/libs/axios';
import '@/libs/components';
import '@/libs/dayjs';
import '@/libs/quasar';

import Default from "./layouts/Default";
import NoSidebar from "./layouts/NoSidebar";

Vue.component('default-layout', Default);
Vue.component('no-sidebar-layout', NoSidebar);

Vue.use(Calendar);
Vue.use(Constants);
Vue.use(Fragment.Plugin);

Vue.mixin(Helpers);

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app');
