<template>
<div class="genre-card">
    <span class="title typography-h3">{{title}}</span>
    <q-icon class="trash my-icon" name="svguse:/icons.svg#trash"  @click.stop="$emit('delete', {id, title})"></q-icon>
    <q-icon class="close my-icon" name="svguse:/icons.svg#close"  @click.stop="$emit('delete')"></q-icon>
</div>
</template>

<script>
export default {
    name: "CardGenre",
    props:{
        title:{
            type: String,
            default: "",
            required: false
        },
        id:{
            type: Number,
            default: 0,
            required: false
        }

    }
}
</script>

<style scoped lang="scss">
    .genre-card.skeleton{
        background-color: $black-100;
        .title{
            height: 24px;
            background-color: $black-200;
            width: 60%;
            color: transparent;
        }
        .trash{
            display: none;
        }
        .close:hover{
            background-color: $black-200;
        }
    }
    .genre-card:not(.skeleton){
        .close{
            display: none;
        }
    }
    .genre-card{
        background: #fff;
        border: 1px solid $black-300;
        padding: 11px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border: 2px solid transparent;
            pointer-events: none;
            transition: all .15s ease-in;
        }
        &:hover{
            &::before{
                border: 2px solid $black-400;
            }
        }
    }
    .genre-card.active{
        &::before{
            border: 2px solid $black-900;
        }

    }
    .trash, .close{
        opacity: 0.6;
        transition: 0.3s all ease;
        cursor: pointer;
        padding: 6px;
    }
    .trash:hover, .close:hover{
        opacity: 1;
    }
    .title{
        user-select: none;
    }
    .trash, .close{
        flex-shrink: 0;
    }
    .trash, .close{
        flex-shrink: 0;
    }
</style>
