<template>
    <q-card>
        <div class="page-title row items-center justify-start bg-white">
            <q-btn
                class="q-mr-sm"
                flat
                @click="handleGoBack"
            >
                <q-icon class="arrow-back" name="svguse:/icons.svg#arrow-right-dark" size="20px" title="Zpět" />
            </q-btn>
            <p class="body-medium q-mb-none">
                Přehled transakcí
            </p>
        </div>
        <q-card-section class="q-card-section">
            <div class="page-info flex items-center justify-start bg-white q-pa-md">
                <div class="flex">
                    <div class="flex items-stretch q-mr-lg">
                        <UberEventAvatar :data="getSaleEvent" />
                    </div>
                    <div class="column">
                        <div class="sale-event-name column items-start">
                            <p class="small-label q-mb-sm">Název události</p>
                            <p class="body-medium q-mb-none">{{ getSaleEvent.name.cs }}</p>
                        </div>
                        <div class="row">
                            <div class="column items-start q-mr-lg">
                                <p class="small-label q-mb-sm">Datum konání</p>
                                <p class="body-medium q-mb-none">{{ getEventDatesForSale(selectedSale) }}</p>
                            </div>
                            <div class="column items-start">
                                <p class="small-label q-mb-sm">Lokace</p>
                                <p class="body-medium q-mb-none">{{ getEventVenue(getSaleEvent).name.cs }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </q-card-section>
        <q-card-section class="q-card-section">
            <div class="column items-stretch justify-start bg-white">
                <div class="sale-number">
                    <div class="row items-end q-pt-md q-px-md">
                        <div class="q-mr-md">
                            <p class="small-label q-mb-sm">Číslo objednávky</p>
                            <h2 class="typography-h2 title q-my-none">{{ selectedSale.number }}</h2>
                        </div>
                        <div class="q-pb-xs">
                            <SaleChip :data="selectedSale" />
                        </div>
                    </div>
                    <div class="q-pt-md q-px-md"> 
                        <p class="small-label q-mb-sm">Cena</p>
                        <h2 class="typography-h2 title q-my-none">{{ selectedSale.totalPrice + ' Kč' }}</h2>
                    </div>
                </div>
                <div class="sale-grid">
                    <div class="grid-area grid-area-1">
                        <p class="small-label q-mb-xs">Objednávka vytvořena</p>
                        <p class="body q-mb-none">{{ formatAnnulledDate(selectedSale.ordered) }}</p>
                    </div>
                    <div class="grid-area grid-area-2">
                        <p class="small-label q-mb-xs">Poslední změna</p>
                        <p class="body q-mb-none">{{ formatAnnulledDate(selectedSale.updated) }}</p>
                    </div>
                    <div class="grid-area grid-area-3">
                        <p class="small-label q-mb-xs">Kupující</p>
                        <p class="body q-mb-xs">{{ selectedSale.contactInfo.firstName + ' ' + selectedSale.contactInfo.lastName }}</p>
                        <div class="body q-mb-xs text-grey">
                            <q-icon
                                v-if="selectedSale.contactInfo.email"
                                class="q-mr-xs"
                                name="svguse:/icons.svg#envelope-simple"
                                size="20px"
                                title="email" />
                            {{ selectedSale.contactInfo.email }}
                        </div>
                        <div class="body q-mb-none text-grey">
                            <q-icon
                                v-if="selectedSale.contactInfo.phone"
                                class="q-mr-xs"
                                name="svguse:/icons.svg#phone"
                                size="20px"
                                title="telefon" />
                            {{ selectedSale.contactInfo.phone }}
                        </div>
                    </div>
                    <div class="grid-area grid-area-4">
                        <p class="small-label q-mb-xs">Objednávka zaplacena</p>
                        <p class="body q-mb-none">{{ formatAnnulledDate(selectedSale.paid) }}</p>
                    </div>
                    <div class="grid-area grid-area-5">
                        <p class="small-label q-mb-xs">Původ transakce</p>
                        <p class="body q-mb-none">{{ selectedSale.isOtc === true ? 'OTC' : 'Online' }}</p>
                    </div>
                </div>
                <div class="row justify-between">
                    <div>
                        <q-btn
                            class="my-btn my-btn--secondary dark no-box-shadow q-mr-lg"
                            icon-right="svguse:/icons.svg#download2x"
                            @click="exportCodes(selectedSale)"
                        >
                            Kódy pro transakci
                        </q-btn>
                        <q-btn class="my-btn my-btn--secondary dark no-box-shadow"
                            icon-right="svguse:/icons.svg#download2x"
                            @click="downloadAndOpenPdf"
                        >
                            Vstupenky
                        </q-btn>
                    </div>
                    <q-btn
                        v-if="!selectedSale.annulled"
                        class="my-btn my-btn--destructive no-border no-box-shadow"
                        icon-right="svguse:/icons.svg#annulled-black"
                        @click="annulSale(selectedSale)"
                    >
                        Anulovat objednávku
                    </q-btn>
                </div>
            </div>
        </q-card-section>
        <q-card-section class="q-card-section q-py-none">
            <div class="row items-stretch justify-start">
                <div
                    class="card-link row items-center justify-center cursor-pointer"
                    :class="{ 'active': active === 'showCards' }"
                    @click="active = 'showCards'"
                >
                    <p class="body-medium q-mb-none">Vstupenky</p>
                </div>
                <div
                    class="card-link row items-center justify-center cursor-pointer"
                    :class="{ 'active': active === 'showHistory' }"
                    @click="active = 'showHistory'"
                >
                    <p class="body-medium q-mb-none">Historie</p>
                </div>
            </div>
        </q-card-section>
        <q-card-section v-if="active === 'showCards'" class="cards-section">
            <div class="cards-section__wrapper">
                <TicketCard
                    v-for="ticket in selectedSale.tickets"
                    :key="ticket.code"
                    :ticket="ticket"
                    :uberEvent="getSaleEvent"
                    @annul="annulTicket(selectedSale, ticket)"
                />
            </div>
        </q-card-section>
        <q-card-section v-if="active === 'showHistory'" class="history-section">
            <div class="history-section__wrapper row items-center justify-center">
                <SaleHistory />
            </div>
        </q-card-section>
    </q-card>
</template>

<script>
export default {
    data() {
        return {
            active: 'showCards',
        }
    },
    computed: {
        getSaleEvent() {
            console.log(this.selectedSale)
            console.log(this.findById(this.uberEvents, this.selectedSale.uberEventRef))
            return this.findById(this.uberEvents, this.selectedSale.uberEventRef)
        }
    },
    methods: {
        handleGoBack() {
            this.$router.push({
                query: null
            })
            this.selectedSale = null
        },
        annulTicket(sale, ticket) {
            this.$q.dialog({
                class:'my-dialog annul',
                title: 'Opravdu anulovat vstupenku?',
                message: 'Vstupenka bude zneplatněna a odpovídající část platby refundována.',
                cancel: true,
                ok: { label: "Ano, anulovat", color: 'red-600' },
                cancel: { label: "Storno", color: 'null', textColor: "primary" },
            }).onOk(async () => {
                const response = await axios.post('/annul', {
                    saleId: sale.id,
                    ticketCode: ticket.code,
                });

                sale = response.data;

                this.updateIn(this.sales, sale);
                this.selectedSale = sale;
            });
        },
        annulSale(sale) {
            this.$q.dialog({
                class:'my-dialog annul',
                title: 'Opravdu anulovat objednávku?',
                message: 'Zahrnuté vstupenky budou zneplatněny a platba refundována.',
                cancel: true,
                ok: { label: "Ano, anulovat", color: 'red-600' },
                cancel: { label: "Storno", color: 'null', textColor: "primary" },
            }).onOk(async () => {
                const response = await axios.post('/annul', {
                    saleId: sale.id,
                });

                sale = response.data;

                this.updateIn(this.sales, sale);
                this.selectedSale = sale;
            });
        },
        async downloadAndOpenPdf() {
            const { data } = await axios.get('/pdf', {
                params: { blockToken: this.selectedSale.blockToken },
                responseType: 'blob',
            });

            this.openBlobInNewTab(data, 'application/pdf');
        },
        formatAnnulledDate(timestamp) {
            return dayjs(timestamp).format('DD. MM. YYYY HH:mm:ss')
        },
        getEventDatesForSale(sale) {
            const ue = this.findById(this.uberEvents, sale.uberEventRef);
            let minFrom, maxTo;

            this.withSale(sale, (ue, e, tg, t) => {
                if (!e) return;
                if (!minFrom || dayjs(e.from).isBefore(dayjs(minFrom))) minFrom = e.from;
                if (!maxTo || dayjs(e.to).isAfter(dayjs(maxTo))) maxTo = e.to;
            });

            return this.formatDateRange(minFrom || ue.from, maxTo || ue.to);
        },
        withSale(sale, fn) {
            const ue = this.findById(this.uberEvents, sale.uberEventRef);
            const result = [];

            for (const ticket of sale.tickets) {
                const e = this.findById(ue.events, ticket.eventRef);
                const tg = this.findById((e || ue).ticketGroups, ticket.ticketGroupRef);

                result.push(fn(ue, e, tg, ticket));
            }

            return result;
        },
        getEventVenue(event) {
            return this.findById(this.venues, event.venueRef)
        }
    },
}
</script>

<style lang="scss">

.sale-event-name {
    margin-bottom: 12px;
}

.arrow-back {
    transform: rotate(180deg);
}

.page-title{
    background: #fff;
    border-bottom: 1px solid $black-200;
    align-items: center;
    padding: 16px;

    h1 {
        padding: 3.5px 0;
    }
}

.q-card-section {
    border-bottom: 1px solid $black-200;
}

.card-link {
    padding: 24px 32px;
    position: relative;

    &.active::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $black-900;
    }
}

.cards-section {
    padding: 32px 16px 96px 16px !important;
    background: $black-50;

    .cards-section__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
    }
}

.history-section {
    padding: 32px 16px 96px 16px !important;
    background: $black-50;
}

.sale-number {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.sale-grid {
    margin: 32px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1px;
    background-color: $black-300;
    border: 1px solid $black-300;

    .grid-area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        background-color: #fff;
    }

    .grid-area-1 { grid-area: 1 / 1 / 2 / 2; }
    .grid-area-2 { grid-area: 1 / 2 / 2 / 3; }
    .grid-area-3 { grid-area: 1 / 3 / 3 / 4; }
    .grid-area-4 { grid-area: 2 / 1 / 3 / 2; }
    .grid-area-5 { grid-area: 2 / 2 / 3 / 3; }
}

</style>