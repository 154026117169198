<template>
    <form class="card-venue-create" @submit.prevent="submitHandler">
        <div class="card-create-errors" v-if="errors.length">
            <div class="card-venue-error tag" v-for="err in errors" :key="err.key">
                {{ err.message }}
            </div>
        </div>
        <div class="spacer" v-else></div>
        <div class="card-venue-create__top row">
            <div class="col-6">
                <InputInlineHeading
                    :label="$t('label.title_in_cs')"
                    :initial-value="content && content.name? content.name.cs : ''" size="big"
                    @input="setNameCs"
                    :error="!!errors.find(err=>err.key==='name_cs')"
                    placeholder="Title"/>
            </div>
            <div class="col-6">
                <InputInlineHeading
                    :label="$t('label.title_in_en')"
                    :initial-value="content && content.name? content.name.en : ''" size="big"
                    @input="setNameEn"
                    placeholder="Title"/>
            </div>
        </div>
        <div class="card-fields">
            <q-input
                tabindex="1"
                v-model="venue.address"
                :error="!!errors.find(err=>err.key==='address')"
                class="my-input my-input--square my-input--square"
                :label="$t('label.address')"

            />
        </div>
        <div class="bottom">

            <div>
<!--                <q-btn class="my-btn my-btn&#45;&#45;secondary" outline>Kontakty <q-icon name="svguse:/icons.svg#download2x" size="20px" /></q-btn>-->
            </div>
            <div class="flex">
                <div class="q-mr-md"><q-btn class="my-btn no-border my-btn--secondary" outline @click="$emit('close')">{{$t('close')}}</q-btn></div>

                <q-btn type="submit" style="min-width: 165px; margin-left: 8px;"
                       class="my-btn my-btn--primary"
                       :disable="disabled===true"
                       color="black"
                       :label="content? $t('update'): $t('add') "/>
            </div>
        </div>
    </form>
</template>

<script>
import InputInlineHeading from "../../InputInlineHeading/InputInlineHeading.vue";
export default {
    name: "CardVenueCreate",
    props:{
      content:{
        type: Object,
        default: null
      }
    },
    components:{
        InputInlineHeading
    },
    data:()=>({
        venue:{
            name: {
                cs: '',
                en: ''
            },
            address: null,
        },
        copy:{
            name: {
                cs: '',
                en: ''
            },
            address: null,
        },
        errors:[],
    }),
    computed:{
        disabled(){
            return  this.copy.address === this.venue.address &&
                    this.copy.name.cs === this.venue.name.cs &&
                    this.copy.name.en === this.venue.name.en;
        }
    },
    watch:{
      venue:{
        handler(val){
            if(val.address){
                this.errors = this.errors.filter(err=>err.key!=='address');
            }
            if(val.name.cs){
                this.errors = this.errors.filter(err=>err.key!=='name_cs');
            }
        },
        deep: true
      }
    },
    methods:{
        submitHandler(){
            this.errors.length = 0;
            if(!this.venue.name.cs || !this.venue.address){
                this.errorMessage = true;
                if(!this.venue.name.cs){
                    this.errors.push({
                        key: 'name_cs',
                        message: this.$t('validation.locations_cz-title-missing')
                    })
                }
                if(!this.venue.address){
                    this.errors.push({
                        key: 'address',
                        message: this.$t('validation.locations_missing')
                    })
                }
                return;
            }
            if (this.content) {
                this.$emit('create', {
                    original: {...this.content},
                    ...this.venue
                })
            } else this.$emit('create', this.venue)
            this.copy.name.cs = this.venue.name.cs;
            this.copy.name.en = this.venue.name.en;
            this.copy.address = this.venue.address;
        },
        setNameCs(title) {
            this.venue.name.cs = title
        },
        setNameEn(title) {
            this.venue.name.en = title
        },
   },

    beforeMount() {
        if(this.content && this.content.name){
            this.copy.name.cs = this.content.name.cs;
            this.copy.name.en = this.content.name.en;
            this.copy.address = this.content.address;

            this.venue.name.cs = this.content.name.cs;
            this.venue.name.en = this.content.name.en;
            this.venue.address = this.content.address;
        }
    }
}
</script>

<style scoped lang="scss">
.card-venue-create{
    padding: 16px 0 32px;
    border: 1px solid $black-300;
    background-color: #fff;
    position: relative;
    &__top{
        margin-top: 16px;
        padding-bottom: 40px;
        padding-left: 16px;
        padding-right: 16px;
    }
}
.bottom{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-fields{
    margin-top: 40px;
}

.card-create-errors{
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;
}
.card-venue-error{
    padding: 4px 8px;
    color: $red-600;
    background: $red-200;

    .q-icon{
        margin-left: 4px;
    }
}
.card-venue-error + .card-venue-error{
    margin-left: 4px;
}
.spacer{
    height: 24px;
}
</style>
