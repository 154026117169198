<template>
    <div class="uber-event-list" @click="showFilters=false">
        <UberEventNewPopup
            @click.stop=""
            @close="showCreate=false"
            @select="handleCreate"
            v-if="showCreate"/>
        <div class="page-title flex justify-between bg-white">
            <h1 class=" typography-h1 title">
                Události
            </h1>
            <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg my-toggle" left-label :label="$t('label.show-archived')" />
        </div>
        <div class="wrapper">
            <div class="controls row justify-between">
                <div class="col-3">
                    <q-btn class="my-btn my-btn--primary full-width"
                           color="black"
                           icon-right="svguse:/icons.svg#plus2px"
                           @click="showCreate = true"
                    >
                        Nová událost
                    </q-btn>
                </div>
                <div class="flex">
                    <div class="filter-wrapper">
                        <UberEventFilters
                            @click.native.stop=""
                            @clear="clearFilters"
                            :applied-filters="filters"
                            @close="showFilters=false"
                            @filter="handleFilterSubmit"
                            v-if="showFilters"
                        />
                        <q-btn
                            @click.stop="showFilters = !showFilters"
                            outline
                            color="grey"
                            class="my-btn empty q-ml-sm"
                            :class="{'active filters-active': showFilters || activeFilters}"

                        >
                            <template #default>
                                <q-icon name="svguse:/icons.svg#filter"/>
                            </template>
                        </q-btn>
                    </div>
                    <q-btn
                        outline
                        color="grey"
                        class="my-btn empty q-ml-sm"
                        @click="$emit('refresh')"
                    >
                        <template #default><q-icon name="svguse:/icons.svg#refresh"/></template>
                    </q-btn>
                    <div class="q-ml-md">
                        <q-input
                            style="width: 270px"
                            outlined
                            class="my-input bg-white"
                            v-model="search"
                            lazy-rules
                            placeholder="Hledat"
                            clearable
                            clear-icon="svguse:/icons.svg#close"
                            dense
                        >
                            <template v-slot:prepend>
                                <q-icon name="svguse:/icons.svg#search" />
                            </template>
                        </q-input>
                    </div>
                </div>
            </div>
            <div>
            <q-table
                class="q-mt-md full-width my-table"
                dense wrap-cells
                :columns="uberEventColumns"
                :data="filteredUberEvents"
                :visible-columns="visibleUberEventColumns"
                :pagination.sync="uberEventPagination"
                :rows-per-page-options="[5, 10, 20, 50, 100]"
                rows-per-page-label="Položek na stránce"
                :row-key="checkId(row || null)"
                :loading="!uberEvents"
                table-class="clickable"
                @row-click="(ev, row) => toggleUberEvent(row, false)"
                >
                <template v-slot:pagination="scope">
                    <p class="description q-table__bottom-item q-ml-lg q-mb-none">
                        {{scope.pagination.page}}
                        /
                        {{scope.pagesNumber }}
                    </p>
                    <q-btn
                        class="page-controls"
                        icon="svguse:/icons.svg#carret-left2px"
                        color="grey-8"
                        dense
                        flat
                        :disable="scope.isFirstPage"
                        @click="scope.prevPage"
                    />

                    <q-btn
                        class="page-controls"
                        icon="svguse:/icons.svg#carret-right2px"
                        color="grey-8"
                        dense
                        flat
                        :disable="scope.isLastPage"
                        @click="scope.nextPage"
                    />
                </template>
                <template v-slot:body-cell-type="props">
                    <q-td>
                        <UberEventAvatar :data="props.row" />
                    </q-td>
                </template>
                <template v-slot:body-cell-name="props">
                    <q-td key="desc">
                        <p class="body-medium q-mb-sm">
                            {{ props.row.event_name ? loc(props.row.event_name): loc(props.row.name)}}
                        </p>
                        <p class="small-label q-mb-none" v-if="props.row.subtitle">{{loc(props.row.subtitle)}}</p>
                    </q-td>
                </template>
                <template v-slot:body-cell-soldTickets="props">
                    <q-td>
                        <p class="body-medium q-mb-sm">
                            {{ getSoldInternalTicketCount(props.row)}}
                        </p>
                        <p class="small-label q-mb-none">Zbývá {{getRemainingInternalTicketCount(props.row)}}</p>
                    </q-td>
                </template>
                <template v-slot:body-cell-date="props">
                    <q-td>
                        <div class="date-cell">
                            <template>
                                <span v-if="checkDays(props.row)" class="body-medium q-mb-sm">{{props.row.event_from ? formattedDate(props.row.event_from) : formattedDate(props.row.from)}} {{ props.row.event_to ? ' - ' + formattedDate(props.row.event_to) : ''}}</span>
                                <span v-else class="body-medium q-mb-sm">{{props.row.event_from ? formattedDate(props.row.event_from) : formattedDate(props.row.from)}}</span>
                                <span class="small-label">
                                {{formattedDateRange(
                                    {
                                        from: props.row.from,
                                        to: props.row.to
                                    }
                                )}}
                            </span>
                            </template>
                        </div>
                    </q-td>
                </template>
                <template v-slot:body-cell-status="props">
                    <q-td class="text-center">
                        <div class="float-left">
                            <q-icon v-if="ueAllTgsAux(props.row)" name="svguse:/icons.svg#whosale" title="Událost je čistě wholesale" />
                            <q-icon v-else-if="ueSellBlocker(props.row)" name="svguse:/icons.svg#warning" color="orange" :title="ueSellBlocker(props.row)" />
                            <q-icon v-else name="svguse:/icons.svg#check-thin" size="20px" color="green" title="Událost je připravena k prodeji" />
                            <q-icon v-if="ueSomeEventSellBlocker(props.row)" name="svguse:/icons.svg#warning" color="orange" title="Některé z podudálostí nejsou připraveny k prodeji" />
                            <q-icon v-if="ueSomeTGSellBlocker(props.row) && !ueSellBlocker(props.row)" name="svguse:/icons.svg#warning" color="orange" title="Některé z kategorií vstupenek nejsou připraveny k prodeji" />
                            
                        </div>
                        <div class="flex float-right">
                            <div class="icon-container" @click.stop="exportCodeEvent(props.row, true)">
                                <q-icon   name="svguse:/icons.svg#upload" />
                            </div>
                            <div class="icon-container" @click.stop="exportCodeEvent(props.row, false)">
                                <q-icon  style="height: 20px;width: 20px;" name="svguse:/excel.svg#excel" />
                            </div>
                            <div v-if="props.row.isEvent" class="icon-container" @click.stop="setEvent(props.row)">
                                <q-icon  name="svguse:/icons.svg#plus"/>
                            </div>
                        </div>
                     
                    </q-td>
                </template>
             
                <template #no-data
                >
                    <div class="my-table__no-data">
                        <q-icon name="svguse:/icons.svg#empty" size="100px" />
                        <p class="body">
                            Nebyly nalezeny žádné výsledky.
                        </p>
                    </div>
                </template>
            </q-table>
        </div>
    </div>
</div>

</template>

<script>
import UberEventNewPopup from "./UberEventNewPopup";
import UberEventFilters from "./UberEventFilters";
import { date } from 'quasar'
export default {
    components: {UberEventFilters, UberEventNewPopup},
    data() {
        return {
            uberEventColumns: [
                { name: 'type', label: 'Druh', field: 'type', align: 'left', sortable: true },
                { 
                    name: 'date',
                    label: 'Datum konání',
                    field: this.getEventFromDate,
                    align: 'center',
                    sortable: true,
                    id: 'date',
                },
                { name: 'name', label: 'Název', field: r => this.loc(r.name), align: 'left', sortable: true},
                { name: 'location', label: 'Lokace', field: r => this.getVenueById(r.venueRef), align: 'left', sortable: true, classes:'body-medium' },
                { name: 'soldTickets', label: 'Prodáno', field: this.getSoldInternalTicketCount, align: 'left', sortable: true },
                { name: 'externalTickets', label: 'Externě', field: this.getExternalTicketCount, align: 'left', sortable: true,classes:'body-medium' },
                { name: 'totalTicketsSold', label: 'Hodnota', field: this.getTotalTicketsSold, align: 'left', sortable: false,classes:'body-medium' },
                { name: 'status', label: 'Status', align: 'center' },
            ],
            uberEventPagination: {
                rowsPerPage: 10,
                // sortBy: 'date',
                ascending: true,
            },
            listArchived: false,
            search: '',
            showCreate: false,
            showFilters: false,
            filters:{
            },
            row: null,
        };
    },
    watch:{
        showCreate(){
            if(document && document.querySelector('body')){
                if(this.showCreate){
                    document.querySelector('body').classList.add('overflow-hidden');
                }else{
                    document.querySelector('body').classList.remove('overflow-hidden');
                }
            }
        },
    },
    computed: {
        activeFilters(){
            return this.filters.date || this.filters.type || this.filters.location || this.filters.state;
        },
        visibleUberEventColumns() {
            let cols = this.uberEventColumns;

            if (this.listArchived) {
                cols = cols.filter(c => c.name !== 'status');
            }

            return cols.map(c => c.name);
        },
        filteredUberEvents() {
            let result = this.uberEvents || [];
            let updatedResults = [];
            updatedResults = this.filterEvents(result);
            updatedResults = this.filterResults(updatedResults);

            const normalizeString = str=> str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

            if(this.filters.location){
                updatedResults = updatedResults.filter(ue=>ue.venueRef === this.filters.location);
            }
            if(this.filters.type){
                updatedResults = updatedResults.filter(ue=>ue.type === this.filters.type);
            }
            if(this.filters.state){
                updatedResults = this.getUberEventsByState(updatedResults, this.filters.state);
            }
            if(this.filters.date){
                updatedResults = this.getUberEventsByDate(updatedResults, this.filters.date);
            }
            
            if (this.listArchived) {
                updatedResults = this.sortEventsByDate(updatedResults);
            }
            
            if(!this.search){
                return updatedResults;
            } 
            
        
            return updatedResults.filter(ue => {
                if(ue.isEvent){
                    return (ue.event_name.cs && normalizeString(ue.event_name.cs).includes(normalizeString(this.search))) || (ue.event_from && normalizeString(this.formattedDate(ue.event_from))).includes(normalizeString(this.search)) ||(ue.event_to &&normalizeString(this.formattedDate(ue.event_to)).includes(normalizeString(this.search)))
                    ||  ( ue.event_name.en && normalizeString(ue.event_name.en).includes(normalizeString(this.search)))
                    ||  ( ue.type && normalizeString(ue.type).includes(normalizeString(this.search))) ;
                }
                return (ue.name.cs && normalizeString(ue.name.cs).includes(normalizeString(this.search)))
                    ||  ( ue.name.en && normalizeString(ue.name.en).includes(normalizeString(this.search)))
                    ||  ( ue.type && normalizeString(ue.type).includes(normalizeString(this.search))) 
                    || (ue.from && normalizeString(this.formattedDate(ue.from)).includes(normalizeString(this.search))) || (ue.to && normalizeString(this.formattedDate(ue.to)).includes(normalizeString(this.search)))
            })
        },
    },

    methods: {
        checkDays(events){
            let date_1, date_2;

            if(events.isEvent && events.event_from && events.event_to){
                date_1 = new Date(events.event_from);
                date_2 = new Date(events.event_to);
            } else if(events.from && events.to){
                date_1 = new Date(events.from);
                date_2 = new Date(events.to);
            }

            if (!date_1 || !date_2) {
                return false;
            }

            var Difference_In_Time = date_2.getTime() - date_1.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            if(Difference_In_Days > 1) {
                return true;
            }
            return false;
        },
        sortEventsByDate(events) {
            events.sort((a, b) => {
                const dateA = a.event_from || a.from;
                const dateB = b.event_from || b.from;
                return new Date(dateB) - new Date(dateA);
            });

            return events;
        },
        async exportCodeEvent(event, isText){
            let exportCodes,exportContactInfo, mergedCP = [];
            let freeTickets;
            if(event.isEvent){
                exportCodes = this.sales
                .filter(s => s.uberEventRef === event.id && s.paid !== null)
                .flatMap(s => s.tickets)
                .filter(t => t.eventRef === event.event_id && !t.annulled)
                .map(t => t.code)
                 //freeTickets
                 for(let i = 0; i < event.events.length; i++) {
                    if(event.events[i].id === event.event_id){
                        freeTickets = event.events[i].ticketGroups.flatMap(tg => [
                            ...(tg.auxTicketCodes) || [],
                        ])
                    }
                }

                let exportCodesSet = new Set(exportCodes);
                let indexes = this.sales.reduce((acc, s, i) => {
                    if (s.uberEventRef === event.id && s.paid !== null) {
                        let hasMatchingTicket = s.tickets.some(t => t.eventRef === event.event_id && !t.annulled && exportCodesSet.has(t.code));
                        if (hasMatchingTicket) {
                        acc.push(i);
                        }
                    }
                    return acc;
                    }, []);
                let contactData = indexes.map(i => this.sales[i]);
                exportContactInfo =  contactData
                .flatMap(s => s.contactInfo)
                .map(t => {
                    return {
                        name: t.firstName + ' ' + t.lastName,
                        email: t.email,
                        phone: t.phone,
                    }
                })
                let exportTotalpaid = contactData
                .map(s =>  {
                    return {   
                        totalPaid: s.totalPrice,
                        tickets: s.tickets.length
                    }
                })
                //merge arrays

                for(let i=0; i < exportContactInfo.length; i++) {
                    mergedCP.push({
                    ...exportContactInfo[i], 
                    ...exportTotalpaid[i]
                    });
                }
            } else { 
                exportCodes =  this.sales
                .filter(s => s.uberEventRef === event.id && s.status === 'paid')
                .flatMap(s => s.tickets)
                .filter(t => !t.annulled)
                .map(t => t.code)
                //freeTickets
                for(let i = 0; i < event.events.length; i++) {
                    if(event.events[i].id === event.event_id){
                        freeTickets = event.events[i].ticketGroups.flatMap(tg => [
                            ...(tg.auxTicketCodes) || [],
                        ])
                    }
                }
                let exportCodesSet = new Set(exportCodes);
                let indexes = this.sales.reduce((acc, s, i) => {
                    if (s.uberEventRef === event.id && s.paid !== null) {
                        let hasMatchingTicket = s.tickets.some(t => !t.annulled && exportCodesSet.has(t.code));
                        if (hasMatchingTicket) {
                        acc.push(i);
                        }
                    }
                    return acc;
                    }, []);
                let contactData = indexes.map(i => this.sales[i]);
                exportContactInfo =  contactData
                .flatMap(s => s.contactInfo)
                .map(t => {
                    return {
                        name: t.firstName + ' ' + t.lastName,
                        email: t.email,
                        phone: t.phone,
                    }
                })
                let exportTotalpaid = contactData
                .map(s =>  {
                    return {   
                        totalPaid: s.totalPrice,
                        tickets: s.tickets.length
                    }
                })
                for(let i=0; i < exportContactInfo.length; i++) {
                    mergedCP.push({
                    ...exportContactInfo[i], 
                    ...exportTotalpaid[i]
                    });
                }
            }
         
            if (exportCodes.length === 0) {
                this.$q.notify({
                    message: 'Nemáte žádné vstupenky k exportu',
                    color: 'negative',
                    icon: 'svguse:/icons.svg#close',
                });
                return;
            }
            if (isText) {
                this.exportText(exportCodes);

            } else {
                this.exportCsv(event,mergedCP,freeTickets);
            }
        },
        checkId(data){
            if(data){
                if(data.isEvent){
                    return data.multiid;
                }
                return data.id;
            }
            return null;
        },
        filterEvents(ue){
            let updatedResults = [];
            ue.forEach(i => {
                if (i.events && i.events.length > 0) {
                    i.events.forEach(e => {
                        updatedResults.push({
                            ...i,
                            event_from: e.from,
                            event_to: e.to,
                            event_id: e.id,
                            event_name: e.name,
                            event_subtitle: e.subtitle ?  e.subtitle : '',
                            event_ticketGroups: e.ticketGroups,
                            isEvent: true,
                            multiid:`${i.id}-${e.id}`,
                        })
                    })
                }else {
                    updatedResults.push(i);
                }
            });
            return updatedResults;
        },
        filterResults(ubEvents){
            const twoDaysBefore = new Date()
            twoDaysBefore.setDate(twoDaysBefore.getDate() - 2);
            if (this.listArchived) {
                return ubEvents.filter(ue => {
                    if (ue.isEvent){
                        return new Date(ue.event_to) < new Date() || new Date(ue.event_from) < new Date();
                    }
                    return new Date(ue.to) < new Date() ||  new Date(ue.from) < new Date();
                });
            } 
            return ubEvents.filter(ue => {
                    if(!ue.archived) {
                        if (ue.isEvent) {
                            return new Date(ue.event_from) > twoDaysBefore || new Date(ue.event_to) > twoDaysBefore;
                        }
                        return  new Date(ue.from) > twoDaysBefore  || new Date(ue.to) > twoDaysBefore || (new Date(ue.publicFrom) > twoDaysBefore ) ;
                    }
            });
        },
        exportText(codes){
            const text = codes.join('\r\n');
            const headername = 'Kódy vstupenek';
            const header = `${headername}\r\n`;
            const blob = new Blob([header, text], { type: 'text/plain;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'codes.txt');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        exportCsv(ue,contacts, freeTickets = []){
            let csvRows = [];
            let headers,values = []

            for(let i=0; i < contacts.length; i++){
                headers = Object.keys(contacts[i]);
            }
           
            csvRows.push(headers.join(','));
            for(let i=0; i < contacts.length; i++){
                values =  Object.values(contacts[i]).join(',');
                csvRows.push(values)
            }

          
            if (freeTickets) {
                for(let i=0; i < freeTickets.length; i++){
                    csvRows.push(',,,,aux,'+freeTickets[i])
                }
                
            }
            const dateStr = dayjs(ue.from ? ue.from : ue.event_from).format('YYYYMMDD_HHmm');
            const nameStr = this.filenamify(this.loc(ue.name));

            let downloadFileName = `${dateStr}_${nameStr}.csv`;
            const jonedArray  = csvRows.join('\n')
            const blob = new Blob([jonedArray], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.setAttribute('href', url)
            a.setAttribute('download', downloadFileName);
            a.click()
        },
        clearFilters(){
            this.filters = {};
            this.showFilters = false;
        },

        getVenueById(id) {
            return this.loc(_.find(this.venues, v => v.id === id).name);
        },
        formattedDateRange(date) {
            if(!date.to) {
                return '';
            }
            if(date.from === date.to) {
                return '';
            }
            return this.formatDateRange(
                (date && date.from),
                (date && date.to),
                false,
                false,
            ) || '-';
        },
        formattedDate(date) {
            return this.formatDate(date) || '';
        },
        sortByDate(a, b) {
            a = a.includes('–') ? a.substr(0, a.indexOf('–') - 1) : a;
            b = b.includes('–') ? b.substr(0, b.indexOf('–') - 1) : b;

            console.log(Math.sign(dayjs(a, this.dateTimeFormat).diff(dayjs(b, this.dateTimeFormat))))

            return Math.sign(dayjs(a, this.dateTimeFormat).diff(dayjs(b, this.dateTimeFormat)));
        },
        venueFilterFn(venue, text) {
            return this.loc(venue.name).toLowerCase().includes(text.toLowerCase());
        },
        getEventFromDate(ue) {
            if(ue.isEvent){
                return ue.event_from;
            } else {
                return ue.from
            }
        },
        getSoldInternalTicketCount(ue) {
            if (ue.isEvent){
                return !this.sales ? '...' : _.sumBy((
                    this.sales.filter(s => s.uberEventRef === ue.id && s.status === 'paid')
                ), s => s.tickets.filter(t => t.eventRef === ue.event_id && !t.annulled).length) 
             }

            return !this.sales ? '...' : _.sumBy((
                this.sales.filter(s => s.uberEventRef === ue.id && s.status === 'paid')
            ), s => s.tickets.filter(t => !t.annulled).length);
        },
        getRemainingInternalTicketCount(ue) {
            if(ue.isEvent){
                return !this.sales ? '...' : _.sumBy([
                    ...ue.event_ticketGroups,
                ], tg => this.tgx(tg, ue, 'ticketCount')) - this.getSoldInternalTicketCount(ue);
            }
            return !this.sales ? '...' : _.sumBy([
                ...ue.ticketGroups,
                ...ue.events.flatMap(e => e.ticketGroups),
            ], tg => this.tgx(tg, ue, 'ticketCount')) - this.getSoldInternalTicketCount(ue);
        },
        getExternalTicketCount(ue) {
            if(ue.isEvent){
                return _.sumBy([
                    ...ue.event_ticketGroups,
                ], 'externalTicketCodes.length');
            }
            return _.sumBy([
                ...ue.ticketGroups,
            ], 'externalTicketCodes.length');
        },
        getTotalTicketsSold(ue) {
            if(ue.isEvent){
                let exportCodes =  this.sales
                .filter(s => s.uberEventRef === ue.id && s.status === 'paid')
                .flatMap(s => s.tickets)
                .filter(t => !t.annulled)
                .map(t => t.code)
                .concat([
                    ...ue.ticketGroups,
                ].flatMap(tg => [
                    ...tg.externalTicketCodes,
                    ...(tg.auxTicketCodes || []),
                ]));
                let exportCodesSet = new Set(exportCodes);
                let indexes = this.sales.reduce((acc, s, i) => {
                    if (s.uberEventRef === ue.id && s.status === 'paid') {
                        let hasMatchingTicket = s.tickets.some(t => t.eventRef === ue.event_id && !t.annulled && exportCodesSet.has(t.code));
                        if (hasMatchingTicket) {
                        acc.push(i);
                        }
                    }
                    return acc;
                    }, []);
                let contactData = indexes.map(i => this.sales[i]);
                let exportTotalpaid = contactData
                .map(s =>  {
                    return {   
                        totalPaid: s.totalPrice,
                        tickets: s.tickets.length
                    }
                })
                let total = 0;
                for(let i=0; i < exportTotalpaid.length; i++){
                    total += exportTotalpaid[i].totalPaid;
                }
                return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
            let exportCodes =  this.sales
                .filter(s => s.uberEventRef === ue.id && s.status === 'paid')
                .flatMap(s => s.tickets)
                .filter(t => !t.annulled)
                .map(t => t.code)
                .concat([
                    ...ue.ticketGroups,
                ].flatMap(tg => [
                    ...tg.externalTicketCodes,
                    ...(tg.auxTicketCodes || []),
                ]));
                let exportCodesSet = new Set(exportCodes);
                let indexes = this.sales.reduce((acc, s, i) => {
                    if (s.uberEventRef === ue.id && s.status === 'paid') {
                        let hasMatchingTicket = s.tickets.some(t => !t.annulled && exportCodesSet.has(t.code));
                        if (hasMatchingTicket) {
                        acc.push(i);
                        }
                    }
                    return acc;
                    }, []);
                let contactData = indexes.map(i => this.sales[i]);
                let exportContactInfo =  contactData
                .flatMap(s => s.contactInfo)
                .map(t => {
                    return {
                        name: t.firstName + ' ' + t.lastName,
                        email: t.email,
                        phone: t.phone,
                    }
                })
                let exportTotalpaid = contactData
                .map(s =>  {
                    return {   
                        totalPaid: s.totalPrice,
                        tickets: s.tickets.length
                    }
                })
                let total = 0;
                for(let i=0; i < exportTotalpaid.length; i++){
                    total += exportTotalpaid[i].totalPaid;
                }
                return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        handleCreate(val){
            this.showCreate = false;
            this.selectedEvent = null;
            this.selectedUberEvent = this.getNewUberEvent(val);
        },
        handleFilterSubmit(filters){
            this.showFilters = false;
            if(_.isEqual(filters, this.filters)){
                return;
            }
            this.filters = {...filters};
        },
        getUberEventsByState(uberEvents, state) {
            return uberEvents.filter(ue => {
                if(state === 'passed'){
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => new Date(e.to) < new Date());
                    }
                    return new Date(ue.to) < new Date();
                }
                if(state === 'current'){
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => new Date(e.from) < new Date() && new Date(e.to) > new Date());
                    }
                    return new Date(ue.from) < new Date() && new Date(ue.to) > new Date();
                }
            });
        },
        getUberEventsByDate(uberEvents, selectedDate){
            if(typeof selectedDate === 'string'){
                return uberEvents.filter(ue=>{
                    if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                        return ue.events.some(e => date.formatDate(e.from, 'YYYY/MM/DD') === selectedDate || date.formatDate(e.to, 'YYYY/MM/DD') === selectedDate)
                    }
                    return date.formatDate(ue.from, 'YYYY/MM/DD') === selectedDate || date.formatDate(ue.to, 'YYYY/MM/DD') === selectedDate;
                });
            }
            return uberEvents.filter(ue=>{
                if(ue.type === 'multikoncert' || ue.type === 'divadlo'){
                    return ue.events.some(e => new Date(e.from) > new Date(selectedDate.from) && new Date(e.from) < new Date(selectedDate.to));
                }
                return new Date(ue.from) > new Date(selectedDate.from) && new Date(ue.from) < new Date(selectedDate.to);
            })
        },
        setEvent(ue){
            this.toggleUberEvent(ue, true);
        }
    },
    mounted() {
        document.querySelector('body').classList.remove('overflow-hidden')
    }
};
</script>
<style lang="scss">
.icon-container{
    width: 2rem; 
    height: 1.75rem;
}

.icon-container:hover{
    box-shadow: 0 0.1px 0.5px rgba(1, 1, 100, 100);
}
.overview-export-btn{
    ::before{
        box-shadow: none !important;
    }
    :hover{
        background: transparent !important;
    }
}
.text-center{
    text-align: center !important;
}
.uber-event-list{
    .controls{
        position: sticky;
        top: 0;
        left: 0;
        z-index: 999;
        padding-top: 16px;
        padding-bottom: 16px;
        background: $black-50;
    }
    .page-title{
        background: #fff;
        border-bottom: 1px solid $black-200;
        align-items: center;
        padding: 24px 16px;
    }
    .wrapper{
        padding: 16px 16px 32px;
        position: relative;
    }
    .filter-wrapper{
        position: relative;
    }
    .filters-active{
        stroke: #fff;
        fill: $black-900 !important;

    }

}
</style>
