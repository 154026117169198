<template>
    <q-layout view="hHh Lpr lff" class="shadow-2">
        <q-drawer
            v-if="navShown"
            v-model="drawer"
            show-if-above

            :mini="!drawer || miniState"
            :width="216"
            :breakpoint="500"
            class="sidebar"
        >
            <div class="sidebar__top">
                <div class="sidebar__top__inner">
                    <router-link :to="{ name: 'uberEvents' }" exact>
                        <q-icon
                            size="32px"
                            class="sidebar__logo"
                            name="svguse:/icons.svg#logo"/>
                    </router-link>
                    <div class="sidebar__top__title-wrapper flex no-wrap items-center justify-between">
                        <div class="sidebar__title cursor-pointer" @click="miniState=true">
                            MeetFactory
                        </div>
                        <q-icon class="sidebar__toggle" @click="miniState=!miniState"
                                name="svguse:/icons.svg#sidebar-icon"/>
                    </div>
                </div>
            </div>
            <q-scroll-area class="fit">

                <q-list class="sidebar__list">
                    <div class="sidebar__list__block">
                        <q-item clickable v-ripple>
                            <router-link
                                :to="{ name: 'uberEvents', query: null }"
                                exact
                                @click.native="selectedUberEvent = null"
                            >
                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#calendar-sidebar"/>
                                </q-item-section>
                                <q-item-section>
                                    Události
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Události
                                </q-tooltip>
                            </router-link>
                        </q-item>
                        <q-item clickable v-ripple>
                            <router-link
                                :to="{ name: 'sales', query: null }"
                                exact
                                @click.native="selectedSale = null"
                            >
                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#credit-card"/>
                                </q-item-section>
                                <q-item-section>
                                    Transakce
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Transakce
                                </q-tooltip>
                            </router-link>
                        </q-item>
                    </div>
                    <div class="sidebar__list__block">
                        <q-item clickable v-ripple>
                            <router-link :to="{ name: 'venues' }" exact>
                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#map-pin"/>
                                </q-item-section>

                                <q-item-section>
                                    Lokace
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Lokace
                                </q-tooltip>
                            </router-link>
                        </q-item>
                        <q-item clickable v-ripple>
                            <router-link :to="{ name: 'promoters' }" exact>

                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#user-square"/>
                                </q-item-section>

                                <q-item-section>
                                    Pořadatelé
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Pořadatelé
                                </q-tooltip>
                            </router-link>

                        </q-item>
                        <q-item clickable v-ripple>
                            <router-link :to="{ name: 'genres' }" exact>

                                <q-item-section avatar>

                                    <q-icon name="svguse:/icons.svg#music-note"/>
                                </q-item-section>

                                <q-item-section>
                                    Žánry
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Žánry
                                </q-tooltip>
                            </router-link>
                        </q-item>
                        <q-item clickable v-ripple>

                            <router-link :to="{ name: 'globalDiscounts' }" exact>
                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#arrow-fat-down"/>
                                </q-item-section>

                                <q-item-section>
                                    Slevy
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Slevy
                                </q-tooltip>
                            </router-link>
                        </q-item>
                    </div>
                    <div class="sidebar__list__block">
                        <q-item clickable v-ripple>
                            <router-link :to="{ name: 'settings' }" exact>
                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#gear"/>
                                </q-item-section>

                                <q-item-section>
                                    Nastavení
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Nastavení
                                </q-tooltip>
                            </router-link>
                        </q-item>
                        <q-item clickable v-ripple>

                            <router-link :to="{ name: 'users' }" exact>
                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#users"/>
                                </q-item-section>

                                <q-item-section>
                                    Uživatelé
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Uživatelé
                                </q-tooltip>
                            </router-link>
                        </q-item>
                        <q-item clickable v-ripple>
                            <router-link :to="{ name: 'myAccount' }" exact>
                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#user-square"/>
                                </q-item-section>

                                <q-item-section>
                                    Můj účet
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Můj účet
                                </q-tooltip>
                            </router-link>
                        </q-item>
                        <q-item clickable v-ripple @click.native="logoutShown">
                            <div class="row q-px-md" @click="logOut">
                                <q-item-section avatar>
                                    <q-icon name="svguse:/icons.svg#sign-out"/>
                                </q-item-section>

                                <q-item-section>
                                    Odhlásit
                                </q-item-section>
                                <q-tooltip anchor="center right" self="center start" v-if="miniState">
                                    Odhlásit
                                </q-tooltip>
                            </div>
                        </q-item>
                    </div>

                </q-list>
            </q-scroll-area>
        </q-drawer>
        <q-page-container>
            <q-page>
                <router-view/>
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
export default {
    computed: {
        navShown() {
            if (this.$route.path.includes('login')) return false
            return (
                this.$route.fullPath.startsWith('/admin/') ||
                (appConfig.storefront.showDevUi && this.loggedIn && localStorage.getItem('authRole') === 'admin')
            );
        },
        logoutShown() {
            return this.$route.name !== 'login' && this.loggedIn;
        },
    },
    data: () => ({
        drawer: false,
        miniState: false,
    }),

    created() {
        const authToken = localStorage.getItem('authToken');

        if (authToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

            this.loggedIn = true;
        }
    },

    meta: {
        titleTemplate: title => `${title} | MeetFactory Ticketing`,
    },
};
</script>
