<template>
    <q-btn color="grey" :label="label" :disable="deleting" @click="del" />
</template>

<script>
export default {
    props: {
        deleteFn: Function,
        errorFn: Function,
        type: String,
        item: Object,
        useArchiveLabel: Boolean,
    },

    data() {
        return {
            deleting: false,
        };
    },

    computed: {
        label() {
            return this.useArchiveLabel ? "Archivovat" : "Smazat";
        },
        typeText() {
            switch (this.type) {
                case 'uberEvent': return 'Událost';
                case 'event': return 'Podudálost';
                case 'ticketGroup': return 'Skupina vstupenek';
                case 'venue': return 'Lokace';
                case 'promoter': return 'Pořadatel';
                case 'genre': return 'Žánr';
                case 'globalDiscount': return 'Sleva';
                case 'user': return 'Uživatel';
            }
        },
        nameText() {
            switch (this.type) {
                case 'event': return this.loc(this.item.name) || this.formatDate(this.item.from);
                case 'ticketGroup': return this.loc(this.item.name) || this.item.label;
                case 'promoter': return this.item.title;
                case 'genre': return this.item.title;
                case 'user': return [this.item.firstName, this.item.lastName].filter(v => v).join(' ');
                default: return this.loc(this.item.name);
            }
        },
        endLetter() {
            if (['promoter', 'genre', 'user'].includes(this.type))
                return '';

            return 'a';
        },
    },

    methods: {
        del() {
            this.$q.dialog({
                title: `Opravdu ${this.useArchiveLabel ? "archivovat" : "smazat"}?`,
                message: `${this.typeText} "${this.nameText}" bude ${this.useArchiveLabel ? "archivován" : "smazán"}${this.endLetter}.`,
                cancel: true,
                ok: { label: "Ano" },
                cancel: { label: "Ne", color: null, textColor: "primary" },
            }).onOk(async () => {
                this.deleting = true;

                try {
                    this.deleteFn && await this.deleteFn();
                } catch (err) {
                    this.errorFn && await this.errorFn();
                }

                this.deleting = false;
            });
        },
    },
};
</script>
