<template>
    <fragment>
        <q-toggle v-if="ticketGroup.templateRef" class="float-right" v-model="ticketGroup.inherit" />
        <div :class="['text-h6 text-ellipsis', markAsSoldOut && 'text-strike']">
            <q-icon v-if="saveBlocker" class="q-mb-xs" name="warning" color="red" />
            <q-icon v-else-if="isAux && sellBlocker" class="q-mb-xs" name="mail" color="blue" />
            <q-icon v-else-if="!isAux && sellBlocker && (!template || ticketGroup.inherit)" class="q-mb-xs" name="warning" color="orange" />
            <span v-if="ticketGroup.label" class="text-italic" :title="ticketGroup.label">{{ ticketGroup.label }}</span>
            <span v-else-if="loc(ticketGroup.name)" :title="loc(ticketGroup.name)">{{ loc(ticketGroup.name) }}</span>
            <span v-else-if="template && template.label" class="text-grey text-italic" :title="template.label">{{ template.label }}</span>
            <span v-else-if="template && loc(template.name)" class="text-grey" :title="loc(template.name)">{{ loc(template.name) }}</span>
        </div>
        <div><strong>Cena:</strong> <FallbackCascade field="price" :format="v => `${v} Kč`" :values="[ticketGroup, template]" /></div>
        <div v-if="isTemplate"><strong>Počet:</strong> {{ ticketGroup.ticketCount || '?' }}</div>
        <template v-else>
            <div>
                <strong>Prodané interní:</strong>
                {{ soldInternalTicketCount }} /
                <FallbackCascade field="ticketCount" :values="[ticketGroup, template]" />
            </div>
            <div><strong>Prodané externí:</strong> {{ ticketGroup.externalTicketCodes.length }}</div>
            <div v-if="ticketGroup.auxTicketCount"><strong>Auxiliární vstupenky:</strong> {{ ticketGroup.auxTicketCount }}</div>
        </template>
    </fragment>
</template>

<script>
export default {
    props: {
        ticketGroup: {
            type: Object,
            required: true,
        },
        forEvent: {
            type: Boolean,
            required: true,
        },
        isTemplate: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        template() {
            return this.tpl(this.ticketGroup, this.selectedUberEvent);
        },
        soldInternalTicketCount() {
            return this.tgSoldInternalTicketCount(this.ticketGroup);
        },
        saveBlocker() {
            return !this.isTemplate && this.tgSaveBlocker(this.ticketGroup, this.selectedUberEvent, this.forEvent ? this.selectedEvent : null);
        },
        sellBlocker() {
            return !this.isTemplate && this.tgSellBlocker(this.ticketGroup, this.selectedUberEvent, this.forEvent ? this.selectedEvent : null);
        },
        markAsSoldOut() {
            return !this.isTemplate && !this.saveBlocker && !this.sellBlocker && this.soldInternalTicketCount === this.ticketGroup.ticketCount;
        },
        isAux() {
            return this.tgIsAux(this.ticketGroup, this.selectedUberEvent);
        },
    },
};
</script>
