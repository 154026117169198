<template>
    <component :is="tag">
        <LangIcon :code="code" :size="iconSize" />
        <TextEdit :value="values[code]" :fallback="finalFallback" :textClass="textClass" tag="span"
                  @input="$set(values, code, $event)" @save="$emit('save', $event)" @cancel="$emit('cancel', $event)" />
        <slot />
    </component>
</template>

<script>
export default {
    props: {
        code: {
            type: String,
            required: true,
        },
        values: {
            type: Object,
            required: true,
        },
        fallback: [Object, String],
        iconSize: Number,
        textClass: String,
        tag: {
            type: String,
            default: 'div',
        },
    },

    computed: {
        finalFallback() {
            if (this.fallback) {
                if (this.fallback[this.code])
                    return this.fallback[this.code];

                if (typeof this.fallback === 'string')
                    return this.fallback;
            }

            return '...';
        },
    },
};
</script>
