<template>
<div class="filters">
    <div class="inner">
        <div class="flex">
            <q-select
                popup-content-class="my-input-select__options"
                class="my-input-select"
                dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                input-debounce="0"
                behavior="menu"
                outlined
                emit-value
                map-options
                v-model="filter.type" :label="$t('label.event_type')"
                :options="typeOptions" />
            <q-select
                popup-content-class="my-input-select__options"
                class="my-input-select"
                dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                input-debounce="0"
                behavior="menu"
                outlined
                emit-value
                map-options
                :value="dateValue"
                :label="$t('label.event_date')"
                >
                <template #prepend>
                    <q-icon name="svguse:/icons.svg#calendar" />
                </template>
                <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                    <q-date
                        first-day-of-week="1"
                        :locale="{
                            days : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                            months : ['Leden' , 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
                            daysShort : ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
                            monthsShort : ['Led' , 'Ún', 'Brez', 'Dub', 'Květ', 'Červ', 'Červen', 'Srpr', 'Zář', 'Říj', 'List', 'Pros'],
                        }"
                        class="my-calendar"
                        range @input="closeDateSelect"
                        v-model="filter.date">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-select>
        </div>
        <div class="flex">
            <q-select
                popup-content-class="my-input-select__options"
                class="my-input-select"
                dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                input-debounce="0"
                behavior="menu"
                outlined
                emit-value
                map-options
                v-model="filter.state"
                :label="$t('label.state')"
                :options="stateOptions" />
            <q-select
                popup-content-class="my-input-select__options"
                class="my-input-select"
                dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                input-debounce="0"
                behavior="menu"
                outlined
                emit-value
                map-options
                v-model="filter.location"
                :label="$t('label.location')"
                :options="venueOptions" >
                <template #prepend>
                    <q-icon name="svguse:/icons.svg#map-pin1px"></q-icon>
                </template>
            </q-select>
        </div>
    </div>
    <div class="bottom">
        <q-btn
            outline
            class="my-btn empty"
            color="grey"
            icon="svguse:/icons.svg#filter-remove"
            @click="clearFilters"
        />
        <div class="row justify-end">
            <div class="q-mr-md">
                <q-btn class="my-btn no-border my-btn--secondary" outline @click="$emit('close')">{{$t('discard')}}</q-btn>
            </div>
            <q-btn type="submit" style="min-width: 165px; margin-left: 8px;"
                   class="my-btn my-btn--primary"
                   color="black"
                   @click="$emit('filter', filter)"
                   :label="$t('apply')"
                   />
        </div>
    </div>
</div>
</template>

<script>
import { date } from 'quasar'

export default {
    props:{
        appliedFilters: {
            type: Object,
            default:null
        },
    },
    name: "UberEventFilters",
    data:()=>({
        filter:{
            type:null,
            date:null,
            location:null,
            state:null
        }
    }),
    created() {
        if(this.appliedFilters){
            this.filter = {...this.appliedFilters}
        }
    },
    computed:{
        dateValue(){
          if(!this.filter.date){
            return null;
          }
          if(typeof this.filter.date === 'string'){
            return date.formatDate(this.filter.date, 'DD/MM/YYYY');
          }
          return `${date.formatDate(this.filter.date.from, 'DD/MM/YY')} - ${date.formatDate(this.filter.date.to, 'DD/MM/YY')}`;
        },
        venueOptions(){
            const opts =  this.venues.map(venue=>{
                return{
                    label: this.loc(venue.name),
                    value: venue.id
                }
            })
            return [{label:this.$t('label.all'), value:''}, ...opts]
        },
        typeOptions(){
            return[
                {label:this.$t('label.all'), value:''},
                {
                    label: 'Koncert',
                    value: 'koncert'
                },
                {
                    label: 'Multikoncert',
                    value: 'multikoncert'
                },
                {
                    label: 'Festival',
                    value: 'festival'
                },
                {
                    label: 'Divadlo',
                    value: 'divadlo'
                },
                {
                    label: 'Výstava',
                    value: 'vystava'
                },
                {
                    label: 'Ostatní',
                    value: 'ostatni'
                }
            ]
        },
        stateOptions(){
            return[
                {label:this.$t('label.all'), value:''},
                {
                    label: 'Uplynulé',
                    value: 'passed'
                },
                {
                    label: 'Aktuální',
                    value: 'current'
                }
            ]
        }
    },
    methods:{
        clearFilters(){
            this.filter = {
                type:null,
                date:null,
                location:null,
                state:null
            }
            this.$emit('clear', this.filter)
        },
        closeDateSelect(){
            this.$refs.qDateProxy.hide()
        }
    },
}
</script>

<style scoped lang="scss">
.filters{
    background: #fff;
    width: 691px;
    padding: 24px;
    position: absolute;
    bottom: -290px;
    right: 0;
    z-index: 998;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.04), 10px 0px 10px -5px rgba(0, 0, 0, 0.15), -10px 0px 10px -5px rgba(0, 0, 0, 0.15), 0px 7px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid $black-300;

}
.bottom{
    margin-top: 48px;
    display: flex;
    justify-content: space-between;

}
</style>
