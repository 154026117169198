<template>
    <q-card class="">
        <q-card-section class="row items-center">
            <q-btn round dense color="primary" icon="add" @click="newGenre()" />
        </q-card-section>
        <q-card-section>
            <q-list dense bordered padding class="rounded-borders">
                <q-item clickable v-ripple :active="selectedGenre === g" v-for="g in filteredGenres" :key="g.id" @click="toggleGenre(g)">
                    <q-item-section>
                        {{ g.title }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
    </q-card>
</template>

<script>
export default {
    data() {
        return {
            listArchived: false,
        };
    },

    computed: {
        filteredGenres() {
            return (this.genres || []).filter(g => this.listArchived ? g.archived : !g.archived);
        },
    },

    methods: {
        newGenre() {
            this.selectedGenre = this.getNewGenre();
        },
    },
};
</script>
