import axios from 'axios';

import { notifyOnApiError } from '@/libs/notify';
import router from '@/libs/router';


const CancelToken = axios.CancelToken;
let source = CancelToken.source();

axios.defaults.baseURL = appConfig.app.baseApiUrl;
axios.defaults.cancelToken = source.token;

axios.cancelAll = () => {
    source.cancel();
    source = CancelToken.source();
    axios.defaults.cancelToken = source.token;
};

axios.interceptors.response.use(null, err => {
    const isAuthError = (err.response && err.response.status === 401);
    const isLoginError = (router.currentRoute.name === 'login');

    // Note: This can be replaced with a custom axios config field when a version with #2295 fixed is released
    const isStorefrontSilentError = /\/(block|release|order|retry)$/.test(err.config.url);

    const isSilentError = (isAuthError || isLoginError || isStorefrontSilentError || axios.isCancel(err));

    if (!isSilentError) {
        // Log error data to console
        if (!_.isEmpty(err.response && err.response.data)) {
            debug.error(err.response.data);
        }

        notifyOnApiError(err);
    }

    // Redirect to login on API auth error
    if (isAuthError && router.currentRoute.name !== 'login') {
        delete axios.defaults.headers.common['Authorization'];

        localStorage.removeItem('authToken');
        localStorage.removeItem('authRole');
        sessionStorage.setItem('redirectPath', router.currentRoute.fullPath);

        router.push({ name: 'login' });
    }

    return Promise.reject(err);
});
