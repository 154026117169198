<template>
    <q-card :class="isArchived ? 'bg-grey-4' : 'bg-green-1'">
        <q-card-section :class="`text-body2 text-${validationColor}`">
            <q-icon :name="validationIcon" :color="validationColor" />
            <span>{{ validationText }}</span>
        </q-card-section>
        <q-card-section>
            <TextEdit v-model="selectedPromoter.title" fallback="⧼ Krátký název ⧽" textClass="h5" title="Krátký název" />
            <TextEdit v-model="selectedPromoter.fullTitle" fallback="⧼ Dlouhý název ⧽" textClass="h6" title="Dlouhý název" />
        </q-card-section>
        <q-card-section>
            <div><strong>IČO:</strong> <TextEdit v-model="selectedPromoter.ico" fallback="⧼ IČO ⧽" title="IČO" tag="span" /></div>
            <div><strong>DIČ:</strong> <TextEdit v-model="selectedPromoter.dic" fallback="⧼ DIČ ⧽" title="DIČ" tag="span" /></div>
        </q-card-section>
        <q-card-section v-if="!isArchived" class="q-gutter-sm">
            <SaveButton :saveFn="save" :disable="saveBlocker" />
            <DeleteButton v-if="selectedPromoter.id" :deleteFn="del" type="promoter" :item="selectedPromoter" />
            <q-btn v-else label="Zrušit" @click="selectedPromoter = null" />
        </q-card-section>
        <q-btn flat round dense class="absolute-top-right q-ma-sm" color="grey" icon="close" @click="selectedPromoter = null;" />
    </q-card>
</template>

<script>
export default {
    computed: {
        isArchived() {
            return this.selectedPromoter.archived;
        },
        saveBlocker() {
            return (
                (this.isArchived && "Pořadatel byl archivován") ||
                (!this.selectedPromoter.title && "Chybí krátký název")
            );
        },
        validationText() {
            return this.saveBlocker || "Pořadatel je plně validní";
        },
        validationColor() {
            return this.isArchived ? 'blue-grey-5' : this.saveBlocker ? 'red' : 'green';
        },
        validationIcon() {
            return this.isArchived ? 'close' : this.saveBlocker ? 'warning' : 'check';
        },
    },

    methods: {
        async save() {
            if (this.selectedPromoter.id) {
                const promoter = await this.update('promoters', this.selectedPromoter);

                this.updateIn(this.promoters, promoter);
                this.selectedPromoter = promoter;
            } else {
                const promoter = await this.create('promoters', this.selectedPromoter);

                this.promoters.push(promoter);
                this.selectedPromoter = promoter;
            }

            notifyOk("Pořadatel byl uložen.");
        },
        async del() {
            const { result, archived } = await this.delete('promoters', this.selectedPromoter);

            if (result === 'destroyed') {
                notifyOk("Pořadatel byl smazán.");

                this.deleteIn(this.promoters, this.selectedPromoter);
            } else if (result === 'archived') {
                notifyOk("Pořadatel byl archivován.");

                this.selectedPromoter.archived = archived;

                this.updateIn(this.promoters, this.selectedPromoter);
            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }

            this.selectedPromoter = null;
        },
    },
};
</script>
