<template>
    <q-card :class="isArchived ? 'bg-grey-4' : 'bg-green-1'">
        <q-card-section :class="`text-body2 text-${validationColor}`">
            <q-icon :name="validationIcon" :color="validationColor" />
            <span>{{ validationText }}</span>
        </q-card-section>
        <q-card-section>
            <div><strong>Jméno:</strong> <TextEdit v-model="selectedUser.firstName" fallback="⧼ Jméno ⧽" title="Jméno" tag="span" /></div>
            <div><strong>Příjmení:</strong> <TextEdit v-model="selectedUser.lastName" fallback="⧼ Příjmení ⧽" title="Příjmení" tag="span" /></div>
            <div><strong>E-mail:</strong> <TextEdit v-model="selectedUser.email" fallback="⧼ E-mail ⧽" title="E-mail" tag="span" /></div>
            <div><strong>Heslo:</strong> <TextEdit v-model="selectedUser.password" :fallback="`⧼ ${passwordPlaceholder} ⧽`" :title="passwordPlaceholder" tag="span" type="password" /></div>
        </q-card-section>
        <q-card-section>
            <div class="row items-center q-gutter-sm">
                <strong>Úroveň přístupu:</strong>
                <q-select dense outlined v-model="selectedUser.role" :options="[{ value: 'admin', label: 'Administrátor' }, { value: 'seller', label: 'Prodavač' }]" emit-value map-options />
            </div>
        </q-card-section>
        <q-card-section>
            <div><strong>Poslední přihlášení:</strong> {{ selectedUser.lastLogin ? formatDate(selectedUser.lastLogin) : '-' }}</div>
        </q-card-section>
        <q-card-section v-if="!isArchived" class="q-gutter-sm">
            <SaveButton :saveFn="save" :disable="saveBlocker" />
            <DeleteButton v-if="selectedUser.id" :deleteFn="del" type="user" :item="selectedUser" useArchiveLabel />
            <q-btn v-else label="Zrušit" @click="selectedUser = null" />
        </q-card-section>
        <q-btn flat round dense class="absolute-top-right q-ma-sm" color="grey" icon="close" @click="selectedUser = null;" />
    </q-card>
</template>

<script>
export default {
    computed: {
        isArchived() {
            return this.selectedUser.archived;
        },
        saveBlocker() {
            return (
                (this.isArchived && "Uživatel byl archivován") ||
                (!this.selectedUser.firstName && "Chybí jméno") ||
                (!this.selectedUser.lastName && "Chybí příjmení") ||
                (!this.selectedUser.email && "Chybí e-mailová adresa") ||
                (!this.isEmail(this.selectedUser.email) && "Neplatná e-mailová adresa")
            );
        },
        validationText() {
            return this.saveBlocker || "Uživatel je plně validní";
        },
        validationColor() {
            return this.isArchived ? 'blue-grey-5' : this.saveBlocker ? 'red' : 'green';
        },
        validationIcon() {
            return this.isArchived ? 'close' : this.saveBlocker ? 'warning' : 'check';
        },
        passwordPlaceholder() {
            return this.selectedUser.id ? 'Nové heslo' : 'Heslo';
        },
    },

    methods: {
        async save() {
            if (this.selectedUser.id) {
                const user = await this.update('users', this.selectedUser);

                this.updateIn(this.users, user);
                this.selectedUser = user;
            } else {
                const user = await this.create('users', this.selectedUser);

                this.users.push(user);
                this.selectedUser = user;
            }

            notifyOk("Uživatel byl uložen.");
        },
        async del() {
            const { result, archived } = await this.delete('users', this.selectedUser);

            if (result === 'destroyed') {
                notifyOk("Uživatel byl smazán.");

                this.deleteIn(this.users, this.selectedUser);
            } else if (result === 'archived') {
                notifyOk("Uživatel byl archivován.");

                this.selectedUser.archived = archived;

                this.updateIn(this.users, this.selectedUser);
            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }

            this.selectedUser = null;
        },
    },
};
</script>
