import Notify from 'quasar/src/plugins/Notify.js';;


function keyToMessage(key, data) {
    switch (key) {
        case 'api.uberEvents.update.eventTicketGroupCannotBeDeleted': return `Kategorii vstupenek nelze smazat, neboť zahrnuje jednu nebo více prodaných vstupenek.`;
        case 'api.uberEvents.update.eventTicketGroupPriceCannotBeSet': return `Kategorii vstupenek nelze změnit cenu, neboť zahrnuje jednu nebo více prodaných vstupenek.`;
        case 'api.uberEvents.update.eventTicketGroupPriceCannotBeUpdated': return `Kategorii vstupenek nelze změnit cenu, neboť zahrnuje jednu nebo více prodaných vstupenek.`;
        case 'api.uberEvents.update.eventTicketGroupPriceCannotBeUnset': return `Kategorii vstupenek nelze změnit cenu, neboť zahrnuje jednu nebo více prodaných vstupenek.`;
        case 'api.uberEvents.update.ticketGroupCannotBeDeleted': return `Kategorii vstupenek nelze smazat, neboť zahrnuje jednu nebo více prodaných vstupenek.`;
        case 'api.uberEvents.update.ticketGroupPriceCannotBeUpdated': return `Kategorii vstupenek nelze změnit cenu, neboť zahrnuje jednu nebo více prodaných vstupenek.`;
        case 'api.uberEvents.update.ticketGroupTemplatePriceCannotBeUpdated': return `Šabloně kategorie vstupenek nelze změnit cenu, neboť zahrnuje jednu nebo více prodaných vstupenek.`;
        case 'api.uberEvents.update.localDiscountCannotBeDeleted': return `Slevu nelze smazat, neboť je přiřazena jedné nebo více kategoriím vstupenek.`;
        case 'api.venues.delete.cannotBeDeleted': return `Lokaci nelze smazat, neboť je přiřazena jedné nebo více aktuálním událostem.`;
        case 'api.promoters.delete.cannotBeDeleted': return `Promotéra nelze smazat, neboť je přiřazen jedné nebo více aktuálním událostem.`;
        case 'api.globalDiscounts.delete.cannotBeDeleted': return `Slevu nelze smazat, neboť je přiřazena jedné nebo více aktuálním událostem.`;
        default: return wrapInfo(key);
    }
}

function wrapInfo(info) {
    return `Chyba při volání API: ${info}`;
}


export function notifyOnApiError(err) {
    const resp = err.response;
    const message = (
        !resp ? wrapInfo(err.message) :
        !resp.data ? wrapInfo(`${resp.status} ${resp.statusText}`) :
        !resp.data.key ? wrapInfo(resp.data.message) :
        keyToMessage(resp.data.key, resp.data.data)
    );

    Notify.create({
        message,
        color: 'red',
        icon: 'error',
    });
}

export function notifyOk(message) {
    Notify.create({
        message,
        color: 'green',
        icon: 'info',
    });
}
