<template>
<div class="uber-popup" @click="$emit('close')">
    <div class="inner" @click.stop="">
        <q-btn class="close svg-white" flat icon="svguse:/icons.svg#close"  @click="$emit('close')"/>
        <h2 class="typography-h2 q-ma-none text-center q-pb-lg">Vyberte druh události</h2>
        <div class="list">
            <div class="item" @click="$emit('select', 'koncert')">
                <div class="avatar body-medium" style="background: #10B981">
                    K
                </div>
                <div class="content">
                    <p class="body-medium q-mb-sm">Koncert</p>
                    <p class="tag text-uppercase text-grey-5 q-mb-none">Jednorázová akce s jedním datem konání a více kategoriemi vstupenek.</p>
                </div>
            </div>
            <div class="item" @click="$emit('select', 'multikoncert')">
                <div class="avatar body-medium" style="background: #105EB9">
                    M
                </div>
                <div class="content">
                    <p class="body-medium q-mb-sm">Multikoncert</p>
                    <p class="tag text-uppercase text-grey-5 q-mb-none">Více koncertů stejného interpreta jako jedna událost.</p>
                </div>
            </div>
            <div class="item" @click="$emit('select', 'festival')">
                <div class="avatar body-medium" style="background: #7B61FF">
                    F
                </div>
                <div class="content">
                    <p class="body-medium q-mb-sm">Festival</p>
                    <p class="tag text-uppercase text-grey-5 q-mb-none">Možnost permanentky či vstupenek na jednotlivé dny/akce v jeho rámci.</p>
                </div>
            </div>
            <div class="item" @click="$emit('select', 'divadlo')">
                <div class="avatar body-medium" style="background: #FC9821">
                    D
                </div>
                <div class="content">
                    <p class="body-medium q-mb-sm">Divadlo</p>
                    <p class="tag text-uppercase text-grey-5 q-mb-none">Prodej vstupenek na jednotlivá představení divadelní inscenace.</p>
                </div>
            </div>
            <div class="item" @click="$emit('select', 'vystava')">
                <div class="avatar body-medium" style="background: #C92614">
                    V
                </div>
                <div class="content">
                    <p class="body-medium q-mb-sm">Výstava</p>
                    <p class="tag text-uppercase text-grey-5 q-mb-none">Vstupenky na události vztahující se k výstavě nebo galerijním akcím.</p>
                </div>
            </div>
            <div class="item" @click="$emit('select', 'ostatni')">
                <div class="avatar body-medium" style="background: #1C9CF8">
                    O
                </div>
                <div class="content">
                    <p class="body-medium q-mb-sm">Ostatní</p>
                    <p class="tag text-uppercase text-grey-5 q-mb-none">Jednorázhová událost např. firemní večírek, či jiný pronájem prostoru.</p>
                </div>
            </div>
        </div>
        <div class="flex justify-end q-pt-lg q-pr-sm">
            <q-btn class="my-btn no-border my-btn--secondary" outline @click="$emit('close')">{{$t('discard')}}</q-btn>
        </div>
    </div>
</div>
</template>

<script>
import UberEventAvatar from "./UberEventAvatar";
export default {
    name: "UberEventNewPopup",
    components: {UberEventAvatar}
}
</script>

<style scoped lang="scss">
.uber-popup{
    position: fixed;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 8000;
    background: rgba(80, 80, 80, 0.55);
    display: flex;
    justify-content: center;
    align-items: center;
}
.inner{
    width: 552px;
    background: #fff;
    padding: 24px 0;
    position: relative;
}
.avatar{
    color: #fff;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item{
    display: flex;
    align-items: center;
    border-top: 1px solid $black-200 ;
    border-bottom: 1px solid $black-200 ;
    cursor: pointer;
    transition: all 0.2s ease;
}
.item:hover{
    background: $black-100;
}
.item+.item{
    border-top: none;
}
.content{
    padding: 16px 20px;
}
.close{
    position: absolute;
    top: -36px;
    right: -48px;
}
</style>
