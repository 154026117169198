<template>
  <q-select
    class="my-special-input"
    :label="label"
    popup-content-class="my-special-input__option"
    float-label="Is Quasar Awesome?"
    radio
    dropdown-icon="svguse:/icons.svg#carret-down| 0 0 16 16"
    filter
    :clearable="isClearable===true"
    ref="select"
    clear-icon="close_icon"
    :disable="disabled===true"
    :options="selectOptions"
    v-model="select"
    :model-value="select"/>
</template>

<script>
import './input-special.scss'

export default {
  props: {
    activeItem:{
          type: Object,
          default: null
      },
    selectOptions: {
      type: Array,
      default: () => ([
        {
          label: '20. 10. 2021 18:00 - 22:00',
          value: '20. 10. 2021 18:00 - 22:00',
          className: 'my-special-input__option'
        },
        {
          label: '20. 10. 2021 - 22. 10. 2021 18:00 - 22:00',
          value: '20. 10. 2021 - 22. 10. 2021 18:00 - 22:00',
          className: 'my-special-input__option'
        },
        {
          label: '20. 10. 2021 18:00 - 23:00',
          value: '20. 10. 2021 18:00 - 23:00',
          className: 'my-special-input__option'
        },
        {
          label: '22. 10. 2021',
          value: '22. 10. 2021',
          className: 'my-special-input__option'
        },
        {
          label: '20. 12. 2021 18:00',
          value: '20. 12. 2021 18:00',
          className: 'my-special-input__option'
        }
      ])
    },
    label: {
      type: String,
      default: 'Datum události'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isClearable: {
      type: Boolean,
      default: false
    }
  },
  name: 'SelectDate',
  data: () => ({
    select: null
  }),
  watch: {
    select() {
        this.$refs.select.blur()
        this.$emit('input', this.select)
    }
  },
  created() {
      if(this.activeItem){
        this.select = this.activeItem
      }
  }
}
</script>
