<template>
    <img :src="require(`@/assets/lang-${code}.png`)" :style="style" />
</template>

<script>
export default {
    props: {
        code: {
            type: String,
            validator: function (value) {
                return ['cs', 'en'].includes(value);
            },
        },
        size: Number,
    },

    computed: {
        style() {
            return {
                width: this.size ? `${this.size}px` : '1em',
                'vertical-align': this.size ? `${this.size * -.1}px` : '-.1em',
                'margin-right': this.size ? `${this.size * .3}px` : '.3em',
            };
        },
    },
};
</script>
