import { render, staticRenderFns } from "./SaleHistory.vue?vue&type=template&id=8ee53f0a&"
import script from "./SaleHistory.vue?vue&type=script&lang=js&"
export * from "./SaleHistory.vue?vue&type=script&lang=js&"
import style0 from "./SaleHistory.vue?vue&type=style&index=0&id=8ee53f0a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QList,QItem,QItemSection,QItemLabel});
