<template>
    <div class="relative-position">
        <v-date-picker ref="picker" is-inline :mode="mode" :value="value" locale="cs" :attributes="attrs"
                       :disabled="disable" :from-page.sync="fromPage" :min-date="futureOnly && new Date()"
                       @input="$emit('input', $event)" @click.native.capture="onClick" />
        <q-chip v-show="!todayShown" dense square clickable ripple color="green" text-color="white" label="Přejít na dnešek"
                class="absolute-bottom-right q-mb-sm q-mr-sm z-top" @click="goToToday" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        extra: true,
        disable: Boolean,
        futureOnly: Boolean,
    },

    data() {
        return {
            fromPage: this.toMonthYear((this.value && (this.value instanceof Date ? this.value : this.value.start)) || new Date()),
        };
    },

    computed: {
        mode() {
            return (!this.value || this.value instanceof Date) ? 'single' : 'range';
        },
        attrs() {
            const attrs = [{ key: 'today', highlight: { color: 'green', fillMode: 'light' }, dates: new Date() }];

            if (this.extra) {
                attrs.push({ key: 'extra', highlight: true, dates: this.extra });
            }

            return attrs;
        },
        todayShown() {
            const today = new Date();

            return this.fromPage.month === today.getMonth() + 1 && this.fromPage.year === today.getFullYear();
        },
    },

    methods: {
        onClick(ev) {
            if (this.disable) {
                ev.preventDefault();
                ev.stopPropagation();
            }
        },
        goToToday() {
            this.$refs.picker.$refs.calendar.showPageRange(new Date());
        },
        toMonthYear(date) {
            return { month: date.getMonth() + 1, year: date.getFullYear() };
        },
    },
};
</script>

