<template>
    <q-btn color="primary" label="Uložit" :disable="!!disable || saving" @click="save" />
</template>

<script>
export default {
    props: {
        saveFn: Function,
        errorFn: Function,
        disable: true,
    },

    data() {
        return {
            saving: false,
        };
    },

    methods: {
        async save() {
            this.saving = true;

            try {
                this.saveFn && await this.saveFn();
            } catch (err) {
                this.errorFn && await this.errorFn();
            }

            this.saving = false;
        },
    },
};
</script>
