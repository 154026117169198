<template>
    <q-card class="bg-yellow-1">
        <q-card-section class="row items-center">
            <div class="col-grow text-h5">Uživatelé</div>
            <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg" label="Archivovaní" />
            <q-btn round dense color="primary" icon="add" @click="newUser()" />
        </q-card-section>
        <q-card-section>
            <q-list dense bordered padding class="rounded-borders">
                <q-item clickable v-ripple :active="selectedUser === u" v-for="u in filteredUsers" :key="u.id" @click="toggleUser(u)">
                    <q-item-section>
                        {{ u.firstName }} {{ u.lastName }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
    </q-card>
</template>

<script>
export default {
    data() {
        return {
            listArchived: false,
        };
    },

    computed: {
        filteredUsers() {
            return (this.users || []).filter(u => !u.isSystemUser && (this.listArchived ? u.archived : !u.archived));
        },
    },

    methods: {
        newUser() {
            this.selectedUser = this.getNewUser();
        },
    },
};
</script>
