'use strict';

import { flatMap } from 'lodash';
import dayjs from 'dayjs';


/// Strings

export function isEmail(str) {
    return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(str);
}

export function isPhone(str) {
    return /^[0-9 ()+-]+$/.test(str);
}

export function ucfirst(str) {
    return `${str.charAt(0).toUpperCase()}${str.substr(1)}`;
}


/// Arrays

export function asKeys(array, valueOrFn) {
    const isFn = (typeof valueOrFn === 'function');

    return array.reduce((o, f) => o[f] = isFn ? valueOrFn(f) : valueOrFn, {});
}


/// Data access

export function findById(items, id) {
    return (!items || !id) ? null : items.find(i => i.id === id);
}

export function findForTicket(ue, ticket) {
    const event = ticket.eventRef ? findById(ue.events, ticket.eventRef) : null;
    const groups = event ? event.ticketGroups : ue.ticketGroups;
    const group = findById(groups, ticket.ticketGroupRef);
    const template = tpl(group, ue);

    return { event, group, template };
}

export function tpl(tg, ue) {
    return findById(ue.ticketGroupTemplates, tg.templateRef);
}

export function tgx(tg, ue, ...fieldsOrFns) {
    const template = tpl(tg, ue);

    for (const fieldOrFn of fieldsOrFns) {
        const value = (typeof fieldOrFn === 'function')
            ? fieldOrFn(tg) ?? (template && fieldOrFn(template))
            : tg[fieldOrFn] ?? (template && template[fieldOrFn]);

        if (value != null)
            return value;
    }

    return null;
}


/// Data validation

export function ueIs(ue, ...types) {
    return types.includes(ue.type);
}

function mapFind(coll, fn) {
    for (const x of coll) {
        const y = fn(x);
        if (y)
            return y;
    }
}

export function wrapMessage(prefix, msg) {
    return msg && `${prefix} ${msg}`;
}

export function ueSaveBlocker(ue) {
    return (
        (ue.archived && "Událost byla archivována") ||
        (!ue.name.cs && "Chybí český název") ||
        (!ue.venueRef && "Chybí lokace") ||
        (mapFind(ue.localDiscounts, ld => !ld.name.cs) && "Lokální sleva: Chybí český název") ||
        mapFind(ue.ticketGroups, tg => (!tg.templateRef || tg.inherit) && wrapMessage("Kategorie vstupenek:", tgSaveBlocker(tg, ue)))
    );
}

export function ueSellBlocker(ue) {
    return (
        ueSaveBlocker(ue) ||
        (!ue.venueRef && "Chybí lokace") ||
        (ueIs(ue, 'koncert', 'ostatni') && (
            (!ue.from && "Chybí datum konání") ||
            (!uePublicFrom(ue) && "Chybí datum začátku předprodeje") ||
            (!uePublicTo(ue) && "Chybí datum konce předprodeje")
        )) ||
        (
            ue.ticketGroups.every(tg => tgSellBlocker(tg, ue)) &&
            ue.events.every(e => eSellBlocker(e, ue)) &&
            "Neobsahuje žádnou prodejnou kategorii vstupenek nebo podudálost"
        )
    );
}

export function ueSomeEventSellBlocker(ue) {
    return mapFind(ue.events, e => !e.archived && eSellBlocker(e, ue) && !eAllTgsAux(e, ue));
}

export function ueSomeTGSellBlocker(ue) {
    return mapFind(ue.ticketGroups, tg => tgSellBlocker(tg, ue) && !tgIsAux(tg, ue));
}

export function eSaveBlocker(e, ue) {
    return (
        ((e.archived || ue.archived) && "Podudálost byla archivována") ||
        (ueIs(ue, 'festival', 'vystava') && !e.name.cs && "Chybí český název") ||
        (ueIs(ue, 'multikoncert', 'divadlo', 'vystava') && !e.from && "Chybí datum konání") ||
        mapFind(e.ticketGroups, tg => (!tg.templateRef || tg.inherit) && wrapMessage("Kategorie vstupenek:", tgSaveBlocker(tg, ue, e)))
    );
}

export function eSellBlocker(e, ue) {
    return (
        eSaveBlocker(e, ue) ||
        (!ePublicFrom(ue, e) && "Chybí datum začátku předprodeje") ||
        (!ePublicTo(ue, e) && "Chybí datum konce předprodeje") ||
        (
            e.ticketGroups.every(tg => (!tg.templateRef || tg.inherit) && tgSellBlocker(tg, ue, e)) &&
            "Neobsahuje žádnou prodejnou kategorii vstupenek"
        )
    );
}

export function eSomeTGSellBlocker(e, ue) {
    return mapFind(e.ticketGroups, tg => (!tg.templateRef || tg.inherit) && tgSellBlocker(tg, ue, e) && !tgIsAux(tg, ue));
}

export function tgSaveBlocker(tg, ue, e) {
    return (
        (tgx(tg, ue, 'ticketCount') < tg.blockedCount && "Celkový počet vstupenek je nižší než počet prodaných vstupenek") ||
        (tgx(tg, ue, 'auxTicketCount') > 0 && tgx(tg, ue, 'auxTicketsFree') == null && "Není nastaveno, mají-li být auxiliární vstupenky zdarma")
    );
}

export function tgSellBlocker(tg, ue, e) {
    return (
        (!tgx(tg, ue, 'price') && "Chybí cena") ||
        (!tgx(tg, ue, 'ticketCount') && tg.availableCount == null && "Chybí počet vstupenek") ||
        (!tgPublicFrom(ue, e, tg) && "Chybí datum začátku předprodeje") ||
        (!tgPublicTo(ue, e, tg) && "Chybí datum konce předprodeje")
    );
}

export function ueAllTgsAux(ue) {
    return (
        (ue.ticketGroups.length && ue.ticketGroups.every(tg => tgSellBlocker(tg, ue) && tgIsAux(tg, ue))) ||
        (ue.events.length && ue.events.every(e => eAllTgsAux(e, ue)))
    );
}

export function eAllTgsAux(e, ue) {
    const tgs = e.ticketGroups.filter(tg => !tg.templateRef || tg.inherit);

    return tgs.length && tgs.every(tg => tgSellBlocker(tg, ue, e) && tgIsAux(tg, ue));
}

export function tgIsAux(tg, ue) {
    return tgx(tg, ue, 'auxTicketCount');
}


/// Data availability

export function sfAvailabilityBlocker(ue, e, tg) {
    return (
        (sfIsPast(ue, e) && 'past') ||
        (!sfCheckPublicFrom(ue, e, tg) && 'too_early') ||
        (!sfCheckPublicTo(ue, e, tg) && 'too_late') ||
        (tg ? sfIsSoldOut(ue, e, tg) && 'sold_out'
            : sfNestedAvailabilityBlocker(ue, e))
    );
}

export function sfNestedAvailabilityBlocker(ue, e) {
    const availableTGs = sfAvailableTGs(ue, e);

    return (
        (sfHasNoAvailableTGs(ue, e, availableTGs) && 'no_groups') ||
        (sfIsSoldOut(ue, e, null, availableTGs) && 'sold_out')
    );
}

export function sfIsPast(ue, e) {
    const obj = e || ue;
    const date = obj.to || obj.from;

    return date && dayjs().isAfter(dayjs(date));
}

export function sfCheckPublicDates(ue, e, tg) {
    return sfCheckPublicFrom(ue, e, tg) && sfCheckPublicTo(ue, e, tg);
}

export function sfCheckPublicFrom(ue, e, tg) {
    const from = tg ? tgPublicFrom(ue, e, tg) :
                  e ? ePublicFrom(ue, e) :
                      uePublicFrom(ue);

    return !from || dayjs().isAfter(dayjs(from));
}

export function sfCheckPublicTo(ue, e, tg) {
    const to = tg ? tgPublicTo(ue, e, tg) :
                e ? ePublicTo(ue, e) :
                    uePublicTo(ue);

    return !to || dayjs().isBefore(dayjs(to));
}

export function sfHasNoAvailableTGs(ue, e, availableTGs = null) {
    availableTGs = availableTGs || sfAvailableTGs(ue, e);

    return availableTGs.length === 0;
}

export function sfAvailableTGs(ue, e) {
    return (
        e ? e.ticketGroups.filter(tg => !tgSellBlocker(tg, ue, e) && sfIsTGAvailable(tg, ue, e)): [
            ...ue.ticketGroups.filter(tg => !tgSellBlocker(tg, ue) && sfIsTGAvailable(tg, ue)),
            ...flatMap(ue.events, e => sfIsEAvailable(e, ue) ? e.ticketGroups.filter(tg => !tgSellBlocker(tg, ue, e) && sfIsTGAvailable(tg, ue, e)) : []),
        ]
    );
}

export function sfIsEAvailable(e, ue) {
    return !e.isArchived && e.isListed && sfCheckPublicDates(ue, e);
}

export function sfIsTGAvailable(tg, ue, e) {
    return (!tg.templateRef || tg.inherit) && tgx(tg, ue, 'isListed') && sfCheckPublicDates(ue, e, tg);
}

export function sfIsSoldOut(ue, e, tg, availableTGs = null) {
    if (tg)
        return !sfTGAvailableCount(tg, ue);

    availableTGs = availableTGs || sfAvailableTGs(ue, e);

    return availableTGs.length && availableTGs.every(g => !sfTGAvailableCount(g, ue));
}

export function sfTGAvailableCount(tg, ue) {
    return tg.availableCount || (tgx(tg, ue, 'ticketCount') - tg.blockedCount);
}


/// Dates - fallback values

export function ueFallbackPublicFrom(ue) {
    return null;
}

export function ueFallbackPublicTo(ue) {
    if (ueIs(ue, 'multikoncert', 'divadlo', 'vystava'))
        return null;

    return ue.from;
}

export function eFallbackPublicFrom(ue, e) {
    return uePublicFrom(ue);
}

export function eFallbackPublicTo(ue, e) {
    if (ueIs(ue, 'multikoncert', 'divadlo', 'vystava'))
        return uePublicTo(ue) || e.from;

    return e.from;
}

export function tgFallbackPublicFrom(ue, e, tg) {
    return e ? (tg.templateRef && tpl(tg, ue).publicFrom) || ePublicFrom(ue, e)
             : uePublicFrom(ue);
}

export function tgFallbackPublicTo(ue, e, tg) {
    return e ? (tg.templateRef && tpl(tg, ue).publicTo) || ePublicTo(ue, e)
             : uePublicTo(ue);
}


/// Dates - final values

export function uePublicFrom(ue) {
    return ue.publicFrom || ueFallbackPublicFrom(ue);
}

export function uePublicTo(ue) {
    return ue.publicTo || ueFallbackPublicTo(ue);
}

export function ePublicFrom(ue, e) {
    return e.publicFrom || eFallbackPublicFrom(ue, e);
}

export function ePublicTo(ue, e) {
    return e.publicTo || eFallbackPublicTo(ue, e);
}

export function tgPublicFrom(ue, e, tg) {
    return tg.publicFrom || tgFallbackPublicFrom(ue, e, tg);
}

export function tgPublicTo(ue, e, tg) {
    return tg.publicTo || tgFallbackPublicTo(ue, e, tg);
}


/// Axios

// Enable JSON response on error for calls with blob or arraybuffer response on success
export function ensureJsonErrors(axiosInstance) {
    axiosInstance.interceptors.response.use(
        response => { return response; },
        err => {
            if (err.request.responseType === 'blob' &&
                err.response.data instanceof Blob &&
                err.response.data.type &&
                err.response.data.type.toLowerCase().indexOf('json') !== -1) {

                return new Promise((resolve, reject) => {
                    let reader = new FileReader();

                    reader.onload = () => {
                        try {
                            err.response.data = JSON.parse(reader.result);
                        } catch (err2) {
                            err.response.data = reader.result;
                        }

                        resolve(Promise.reject(err));
                    };

                    reader.onerror = () => {
                        reject(err);
                    };

                    reader.readAsText(err.response.data);
                });
            }

            if (err.config.responseType === 'arraybuffer' && err.response) {
                return new Promise(resolve => {
                    err.response.data = err.response.data.toString();

                    try {
                        err.response.data = JSON.parse(err.response.data);
                    } catch (err2) {}

                    resolve(Promise.reject(err));
                });
            }

            return Promise.reject(err);
        },
    );

    return axiosInstance;
}
