<template>
    <q-card-section class="row q-col-gutter-md">
        <div v-for="tg in orderedTicketGroups" :key="tg.id" class="col-3">
            <q-card :flat="tg.templateRef && !tg.inherit"
                    :bordered="tg.templateRef && !tg.inherit"
                    :class="{
                        'bg-orange-1': tg === selectedTicketGroup,
                        'bg-red-1 text-grey': tg.templateRef && !tg.inherit,
                    }">
                <q-item class="q-pa-md" :clickable="!tg.templateRef || tg.inherit" @click="toggleTicketGroup(tg)">
                    <div class="full-width q-gutter-xs">
                        <TicketGroupListItem :ticketGroup="tg" :forEvent="forEvent" :isTemplate="asTemplates" />
                    </div>
                </q-item>
                <q-btn v-if="!tg.templateRef" flat round dense class="absolute-bottom-right q-ma-xs" color="grey" icon="delete" @click="del(tg)" />
            </q-card>
        </div>
        <div v-if="selectedTG" class="col-12">
            <q-card class="bg-orange-1">
                <q-item class="q-pa-md">
                    <div class="full-width q-gutter-xs">
                        <TicketGroupDetails :forEvent="forEvent" :isTemplate="asTemplates" :saveFn="saveFn" @refresh="$emit('refresh')" />
                    </div>
                </q-item>
                <q-btn v-if="!selectedTG.templateRef" flat round dense class="absolute-bottom-right q-ma-xs" color="grey" icon="delete" @click="del(selectedTG)" />
            </q-card>
        </div>
    </q-card-section>
</template>

<script>
export default {
    props: {
        ticketGroups: {
            type: Array,
            required: true,
        },
        forEvent: {
            type: Boolean,
            required: true,
        },
        asTemplates: {
            type: Boolean,
            default: false,
        },
        saveFn: Function,
    },

    computed: {
        orderedTicketGroups() {
            return _.sortBy(this.ticketGroups, ['templateRef', 'id']);
        },
        selectedTG() {
            return this.ticketGroups.includes(this.selectedTicketGroup) ? this.selectedTicketGroup : null;
        },
    },

    methods: {
        countInherited(template) {
            return this.selectedUberEvent.events.filter(e => e.ticketGroups.find(tg => tg.templateRef === template.id && tg.inherit)).length;
        },
        del(ticketGroup) {
            const inheritedCount = (!this.forEvent && this.asTemplates) ? this.countInherited(ticketGroup) : 0;
            const name = ticketGroup.label || this.loc(ticketGroup.name);
            const nameStr = name ? `"${name}"` : '';

            this.$q.dialog({
                title: "Opravdu smazat?",
                message: (
                    (this.asTemplates ? `Šablona kategorie ${nameStr}` : `Kategorie ${nameStr}`) +
                    " bude definitivně smazána po uložení nadřazené události." +
                    (inheritedCount ? `<br><br>Budou smazány též zděděné skupiny v podudálostech (${inheritedCount}).` : '')
                ),
                html: true,
                cancel: true,
                ok: { label: "Ano" },
                cancel: { label: "Ne", color: null, textColor: "primary" },
            }).onOk(() => {
                this.del_(ticketGroup);
            });
        },
        del_(ticketGroup) {
            const a = this.forEvent ? 'selectedEvent' : 'selectedUberEvent';
            const b = this.asTemplates ? 'ticketGroupTemplates' : 'ticketGroups';

            this.deleteIn(this[a][b], ticketGroup);

            if (this.selectedTicketGroup === ticketGroup) {
                this.selectedTicketGroup = null;
            }

            // If group is template, delete instances in events
            if (this.asTemplates) {
                for (const e of [...this.selectedUberEvent.events, this.selectedEvent]) {
                    if (e) {
                        const idx = e.ticketGroups.findIndex(tg => tg.templateRef === ticketGroup.id);

                        e.ticketGroups.splice(idx, 1);
                    }
                }
            }
        },
    },
};
</script>
