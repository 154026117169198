<template>
<!--    <q-avatar :color="color" text-color="white" size="24px" :title="title">{{ letter }}</q-avatar>-->
    <div class="box body-medium" :style="`background:${color};`">
        {{title}}
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },

    computed: {
        color() {
            switch (this.data.type) {
                case 'koncert': return '#10B981';
                case 'multikoncert': return '#105EB9';
                case 'festival': return '#7B61FF';
                case 'divadlo': return '#FC9821';
                case 'vystava': return '#C92614';
                case 'ostatni': return '#1C9CF8';
            }
        },
        title() {
            return this.$tc(`event.type.${this.data.type}`, 1)[0];
        },
        letter() {
            return this.title[0];
        },
    },
};
</script>
<style lang="scss" scoped>
.box{
    width: 80px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
</style>
