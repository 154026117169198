<template>
    <q-tree :nodes="uesToNodes" node-key="id" default-expand-all>
        <template v-slot:header-root="{ node }">
            <div class="relative z-top full-width row justify-between">
                <div class="text-h6">{{ node.label }}</div>
                <div class="q-ml-md q-mr-xs q-gutter-xs" @click.stop>
                    <q-btn-toggle v-model="showAllUberEvents" :options="[{ label: 'U', value: true }]" clearable />
                    <q-btn-toggle v-model="showAllEvents" :options="[{ label: 'E', value: true }]" clearable />
                    <q-btn-toggle v-model="showAllTicketGroups" :options="[{ label: 'G', value: true }]" clearable />
                </div>
            </div>
            <div class="absolute-top-left fit" style="cursor: default;" @click.stop></div>
        </template>
        <template v-slot:header-uberEvent="{ node }">
            <div :class="{ 'text-italic text-grey': !node.isListed }" @[node.linkToken?`click`:null].stop>
                <span class="on-left">
                    <UberEventAvatar :data="node" />
                </span>
                <TokenLink v-if="node.linkToken" :token="node.linkToken" :text="node.label" invertUnderline />
                <span v-else>{{ node.label }}</span>
            </div>
        </template>
        <template v-slot:default-header="{ node }">
            <div :class="{ 'text-italic text-grey': !node.isListed }" @[node.linkToken?`click`:null].stop>
                <TokenLink v-if="node.linkToken" :token="node.linkToken" :text="node.label" invertUnderline />
                <span v-else>{{ node.label }}</span>
            </div>
        </template>
    </q-tree>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
    props: {
        uberEvent: Object,
        event: Object,
        ticketGroup: Object,
    },

    computed: {
        ...sync([
            'eventTree@showAllUberEvents',
            'eventTree@showAllEvents',
            'eventTree@showAllTicketGroups',
        ]),
        uesToNodes() {
            return [{
                header: 'root',
                id: 'root',
                label: this.$t('storefront.event_overview'),
                children: _.map(this.uberEvents.filter(ue => this.showAllUberEvents || ue === this.uberEvent), ue => ({
                    header: 'uberEvent',
                    id: `${ue.id}`,
                    label: ue.label || this.loc(ue.name),
                    isListed: ue.isListed,
                    linkToken: ue.linkToken,
                    type: ue.type,
                    children: _.concat(
                        _.map(ue.ticketGroups.filter(tg => this.showAllTicketGroups || tg === this.ticketGroup), tg => ({
                            header: 'ticketGroup',
                            id: `${ue.id}:${tg.id}`,
                            label: this.getTgNodeLabel(ue, tg),
                            isListed: this.tgx(tg, ue, 'isListed'),
                            linkToken: tg.linkToken,
                        })).filter(n => n.linkToken),
                        _.map(ue.events.filter(e => this.showAllEvents || e === this.event), e => ({
                            header: 'event',
                            id: `${ue.id}_${e.id}`,
                            label: e.label || this.loc(e.name) || this.formatDateRange(e.from, e.to),
                            isListed: e.isListed,
                            linkToken: e.linkToken,
                            children: _.map(e.ticketGroups.filter(tg => this.showAllTicketGroups || tg === this.ticketGroup), tg => ({
                                header: 'ticketGroup',
                                id: `${ue.id}_${e.id}:${tg.id}`,
                                label: this.getTgNodeLabel(ue, tg),
                                isListed: this.tgx(tg, ue, 'isListed'),
                                linkToken: tg.linkToken,
                            })).filter(n => n.linkToken),
                        })).filter(n => n.linkToken || n.children.length),
                    ),
                })).filter(n => n.linkToken || n.children.length),
            }];
        },
    },

    methods: {
        getTgNodeLabel(uberEvent, ticketGroup) {
            const text = this.tgx(ticketGroup, uberEvent, 'label', x => this.loc(x.name));
            const price = this.tgx(ticketGroup, uberEvent, 'price');
            const priceStr = `${price} Kč`;

            return text ? `${text} (${priceStr})` : priceStr;
        },
    },
};
</script>

<style lang="scss">
.q-tree__node {
    padding-bottom: 0;
}

.q-tree__node-header {
    padding: 2px;
}
</style>
