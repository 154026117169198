import { render, staticRenderFns } from "./CardUserCreate.vue?vue&type=template&id=2a4fc469&scoped=true&"
import script from "./CardUserCreate.vue?vue&type=script&lang=js&"
export * from "./CardUserCreate.vue?vue&type=script&lang=js&"
import style0 from "./CardUserCreate.vue?vue&type=style&index=0&id=2a4fc469&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a4fc469",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QSelect,QBtn,QIcon});
