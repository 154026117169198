import Vue from 'vue';
import Router from 'vue-router';

const Login = () => import(/* webpackChunkName: "login" */ '@/views/Login.vue');
const UberEvents = () => import(/* webpackChunkName: "uberEvents" */ '@/views/UberEvents.vue');
const Venues = () => import(/* webpackChunkName: "venues" */ '@/views/Venues.vue');
const Promoters = () => import(/* webpackChunkName: "promoters" */ '@/views/Promoters.vue');
const Genres = () => import(/* webpackChunkName: "genres" */ '@/views/Genres.vue');
const GlobalDiscounts = () => import(/* webpackChunkName: "globalDiscounts" */ '@/views/GlobalDiscounts.vue');
const PaymentSuccess = () => import(/* webpackChunkName: "paymentSuccess" */ '@/views/PaymentSuccess.vue');
const Sales = () => import(/* webpackChunkName: "sales" */ '@/views/Sales.vue');
const Users = () => import(/* webpackChunkName: "users" */ '@/views/Users.vue');
const Settings = () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue');
const MyAccount = () => import(/* webpackChunkName: "myAccount" */ '@/views/MyAccount.vue');
const Storefront = () => import(/* webpackChunkName: "storefront" */ '@/views/Storefront.vue');
const Otc = () => import(/* webpackChunkName: "otc" */ '@/views/Otc.vue');
const NotFound = () => import(/* webpackChunkName: "notFound" */ '@/views/NotFound.vue');

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
        path: '/',
        name: 'home',
        redirect: '/admin/udalosti',
    }, {
        path: '/admin/login',
        name: 'login',
        component: Login,
        meta: {
            isPublic: true,
        },
    }, {
        path: '/admin/udalosti',
        name: 'uberEvents',
        component: UberEvents,
    }, {
        path: '/admin/lokace',
        name: 'venues',
        component: Venues,
    }, {
        path: '/admin/zanry',
        name: 'genres',
        component: Genres,
    }, {
        path: '/admin/promoteri',
        name: 'promoters',
        component: Promoters,
    }, {
        path: '/admin/slevy',
        name: 'globalDiscounts',
        component: GlobalDiscounts,
    }, {
        path: '/admin/transakce',
        name: 'sales',
        component: Sales,
    }, {
        path: '/admin/uzivatele',
        name: 'users',
        component: Users,
    }, {
        path: '/admin/nastaveni',
        name: 'settings',
        component: Settings,
    }, {
        path: '/admin/mujUcet',
        name: 'myAccount',
        component: MyAccount,
    }, {
        path: '/:lang/otc',
        name: 'otc',
        component: Otc,
    }, {
        path: '/:lang/listky/:token',
        name: 'storefront',
        component: Storefront,
        meta: {
            isPublic: true,
            layout: 'no-sidebar',
        },
    }, {
        path: '/:lang/success',
        name: 'paymentSuccess',
        component: PaymentSuccess,
        meta: {
            isPublic: true,
            layout: 'no-sidebar',
        },
    }, {
        path: '*',
        name: 'notFound',
        component: NotFound,
        meta: {
            isPublic: true,
        },
    }],
});

router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
        axios.cancelAll();
    }

    if (to.name === 'login' && localStorage.getItem('authToken')) {
        const path = sessionStorage.getItem('redirectPath') || '/';
        sessionStorage.removeItem('redirectPath');
        next(path);
        return;
    }

    if (!to.meta.isPublic && !localStorage.getItem('authToken')) {
        sessionStorage.setItem('redirectPath', to.fullPath);
        next({ name: 'login' });
        return;
    }

    if (!to.meta.isPublic && to.name !== 'otc' && localStorage.getItem('authRole') === 'seller') {
        next('/cs/otc');
        return;
    }

    next();
});

export default router;
