<template>
    <q-chip class="my-chip" dense :style="`background:${color}`" text-color="white" :label="label" />
</template>

<script>
export default {
    props: {
        data: Object,
    },

    computed: {
        color() {
            switch (this.data.status) {
                case 'ordered': return sale.finalizationError ? 'text-red' : 'text-blue';
                case 'paid': return '#10B981';
                case 'canceled': return 'text-red-10';
                case 'expired': return 'text-orange';
                case 'annulled': return 'text-grey';
                default: return null;
            }
        },
        label() {
            switch (this.data.status) {
                case 'ordered': return sale.finalizationError ? 'CHYBA' : 'Nová';
                case 'paid': return 'Zaplacená';
                case 'canceled': return 'Zrušená';
                case 'expired': return 'Vypršelá';
                case 'annulled': return 'Anulovaná';
                default: return '?';
            }
        } 
    },
};
</script>
