<template>
    <TextEdit v-model="stringValue" :fallback="stringFallback" type="number" v-bind="$attrs" @save="$emit('save', $event)" @cancel="$emit('cancel', $event)" />
</template>

<script>
export default {
    props: {
        value: Number,
        fallback: [Number, String],
    },

    computed: {
        stringValue: {
            get() {
                return this.value == null ? null : `${this.value}`;
            },
            set(value) {
                const intValue = Math.floor(parseInt(value));

                this.$emit('input', Number.isNaN(intValue) ? null : intValue);
            },
        },
        stringFallback() {
            return this.fallback == null ? null : `${this.fallback}`;
        },
    },
};
</script>
