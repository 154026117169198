<template>
    <q-card class="bg-yellow-1">
        <q-card-section class="row items-center">
            <div class="col-grow text-h5">Lokace</div>
            <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg" label="Archivované" />
            <q-btn round dense color="primary" icon="add" @click="newVenue()" />
        </q-card-section>
        <q-card-section>
            <q-list dense bordered padding class="rounded-borders">
                <q-item clickable v-ripple :active="selectedVenue === v" v-for="v in filteredVenues" :key="v.id" @click="toggleVenue(v)">
                    <q-item-section>
                        {{ loc(v.name) }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
    </q-card>
</template>

<script>
export default {
    data() {
        return {
            listArchived: false,
        };
    },

    computed: {
        filteredVenues() {
            return (this.venues || []).filter(v => this.listArchived ? v.archived : !v.archived);
        },
    },

    methods: {
        newVenue() {
            this.selectedVenue = this.getNewVenue();
        },
    },
};
</script>
