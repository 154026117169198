<template>
    <div>
        <q-select
            popup-content-class="my-input-select__option"
            class="my-input-select venues"
            dropdown-icon="svguse:/icons.svg#carret-down--stroke"
            input-debounce="0"
            behavior="menu"
            outlined
            emit-value
            map-options
            v-model="promoter"
            label="Vybrat pořadatele"
            :options="promoters"
            :option-label="o => o.title"
            @input="$emit('input', $event)"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: Number,
    },
    data() {
        return {
            promoter: this.getPromoterByName,
        }
    },
    computed: {
        getPromoterByName() {
            const v = this.findById(this.promoters, this.value)

            return !v ? null : this.loc(v.title)
        }
    },
    methods: {
        getPromoterTitle(id) {
            const p = this.findById(this.promoters, id);

            return !p ? null : p.title;
        },
    },
    mounted() {
        console.log(this.promoters)
    }
};
</script>
