import { render, staticRenderFns } from "./CardGenreCreate.vue?vue&type=template&id=95314a54&scoped=true&"
import script from "./CardGenreCreate.vue?vue&type=script&lang=js&"
export * from "./CardGenreCreate.vue?vue&type=script&lang=js&"
import style0 from "./CardGenreCreate.vue?vue&type=style&index=0&id=95314a54&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95314a54",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon});
