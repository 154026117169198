<template>
    <div class="box body-medium" :style="`background:${color};`">
        <q-icon :name="`svguse:/icons.svg#${icon}`" size="32px" :title="title" />
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    computed: {
        color() {
            switch (this.data.toLowerCase()) {
                case 'blocked': return '#FC9821';
                case 'ordered': return '#105EB9';
                case 'new': return '#105EB9';
                case 'pending': return '#105EB9';
                case 'canceled': return '#C92614';
                case 'completed': return '#10B981';
                case 'expired': return '#C92614';
                case 'paid': return '#10B981';
                case 'annulled': return '#767676';
                case 'refunded': return '#10B981';
            }
        },
        title() {
            return this.data;
        },
        icon() {
            switch (this.data.toLowerCase()) {
                case 'blocked': return 'ticket';
                case 'ordered': return 'credit-card-white';
                case 'new': return 'bank';
                case 'pending': return 'bank';
                case 'completed': return 'check-white';
                case 'canceled': return 'close-white';
                case 'expired': return 'clock-white';
                case 'paid': return 'envelope-sent';
                case 'annulled': return 'annulled-white';
                case 'refunded': return 'annulled-white';
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.box{
    width: 80px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
</style>
