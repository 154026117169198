import Vue from 'vue';

import '@/styles/quasar.scss';
import '@quasar/extras/material-icons/material-icons.css';
import '../ui/index.scss';
import Dialog from 'quasar/src/plugins/Dialog.js';import Meta from 'quasar/src/plugins/Meta.js';import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';;


Vue.use(Quasar, {
    config: {
            loadingBar: {
            color: 'green',
        },
        brand:{
            'black-400' : "#A3A3A3"
        }
    },
    plugins: {
        Dialog,
        Meta,
        Notify,
    },
});
