<template>
    <q-card
        :flat="!!ticket.annulled"
        :bordered="!!ticket.annulled"
        class="ticket-card no-box-shadow no-border-radius"
        :class="ticket.annulled && 'annulled'"
    >
        <q-card-section
            v-if="ticket.code"
            class="row items-center justify-between"
            :class="{ 'text-grey': ticket.annulled }"
        >
            <h3 class="typography-h3 title q-my-none">{{ ticket.code }}</h3>
            <q-btn
                v-if="ticket.code && !isPast"
                flat
                dense
                class="row items-center q-py-sm q-px-md no-border-radius"
                :class="{ 'bg-grey': ticket.annulled }"
                color="grey"
                @click="!ticket.annulled ? $emit('annul') : null"
            >
                <p
                    class="tag q-mb-none text-uppercase text-weight-bold q-mr-sm"
                    :class="!ticket.annulled ? 'text-grey' : 'text-white'"
                >
                    {{ ticket.annulled ? formatAnnulledDate(ticket.annulled) : 'Anulovat' }}
                </p>
                <q-icon
                    :name="!ticket.annulled ? 'svguse:/icons.svg#annulled' : 'svguse:/icons.svg#annulled-white'"
                    size="20px"
                    title="Anulováno"
                />
            </q-btn>
        </q-card-section>
        <q-card-section>
            <p
                class="body-medium q-mb-sm"
                :class="{ 'text-grey': ticket.annulled }"
            >
                {{ loc(uberEvent.name) }}
            </p>
            <p
                class="body-medium q-mb-sm"
                :class="{ 'text-grey': ticket.annulled }"
            >
                {{ event && loc(event.name) }}
            </p>
            <div class="row items-center">
                <q-icon
                    :name="!ticket.annulled ? 'svguse:/icons.svg#calendar' : 'svguse:/icons.svg#calendar-grey'"
                    size="20px"
                    title="Kalendář"
                />
                <p
                    class="body-medium q-ml-xs q-mb-none"
                    :class="{ 'text-grey': ticket.annulled }"
                >
                    {{ formatDateRange(from, to) }}
                </p>
            </div>
        </q-card-section>
        <q-card-section class="q-card-section">
            <div class="row items-end justify-between">
                <div class="column items-start">
                    <p class="small-label q-mb-sm">Cena</p>
                    <p
                        class="typography-h2 title q-my-none"
                        :class="{ 'text-grey': ticket.annulled }"
                    >
                        {{ ticket.finalPrice }} Kč
                    </p>
                </div>
                <!-- <q-btn
                    v-if="!ticket.annulled"
                    flat
                    class="my-btn my-btn--destructive no-border no-box-shadow"
                >
                    <p class="body-medium q-mb-none q-mr-sm">Vstupenka</p>
                    <q-icon name="svguse:/icons.svg#download2x" size="20px" title="Stáhnout" />
                </q-btn> -->
                <q-chip v-if="ticket.annulled" class="my-chip" dense :style="`background:${refundStatusColor(ticket.refundStatus)}`" text-color="white" :label="translateRefundStatus(ticket.refundStatus)" />
            </div>
        </q-card-section>
        <q-card-section>
            <div>
                <div>
                    <p class="small-label q-mb-xs">Kategorie</p>
                    <p class="body-medium q-mb-sm text-grey">{{ ticketGroupName || '&lt;bez názvu&gt;' }} ({{ ticketGroupPrice }} Kč)</p>
                </div>
                <div>
                    <p class="small-label q-mb-xs">Sleva</p>
                    <p v-if="discount" class="body-medium q-mb-none text-grey">{{ loc(discount.name) }} ({{ discountValue }})</p>
                    <p v-else class="body-medium q-mb-none text-grey">-</p>
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>

<script>
export default {
    props: {
        ticket: {
            type: Object,
            required: true,
        },
        uberEvent: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            event: null,
            ticketGroup: null,
        };
    },
    computed: {
        from() {
            return (this.event && this.event.from) || this.uberEvent.from;
        },
        to() {
            return (this.event && this.event.to) || (this.event && this.event.from ? null : this.uberEvent.to);
        },
        ticketGroupName() {
            return this.tgxx('label', tg => this.loc(tg.name));
        },
        ticketGroupPrice() {
            return this.tgxx('price');
        },
        discount() {
            if (this.ticket.globalDiscountRef) {
                return this.findById(this.globalDiscounts, this.ticket.globalDiscountRef);
            } else if (this.ticket.localDiscountRef) {
                return this.findById(this.uberEvent.localDiscounts, this.ticket.localDiscountRef);
            } else {
                return null;
            }
        },
        discountValue() {
            if (!this.discount)
                return null;

            if (this.discount.replacementPrice)
                return `${this.discount.replacementPrice} Kč`;

            const multiplier = this.discount.multiplier;
            const percentage = multiplier != null ? Math.round((multiplier - 1) * 100) : null;
            const sign = (percentage > 0) ? '+' : '';

            return multiplier != null ? `${sign}${percentage}%` : '-';
        },
        isPast() {
            return this.sfIsPast(this.uberEvent, this.event);
        },
    },

    watch: {
        ticket: 'init',
        uberEvent: 'init',
    },

    methods: {
        init() {
            const ue = this.uberEvent;
            const e = this.findById(ue.events, this.ticket.eventRef);
            const tg = this.findById((e || ue).ticketGroups, this.ticket.ticketGroupRef);

            this.event = e;
            this.ticketGroup = tg;
        },
        tgxx(...fieldsOrFns) {
            return this.tgx(this.ticketGroup, this.uberEvent, ...fieldsOrFns);
        },
        refundStatusColor(status) {
            switch (status) {
                case 'PENDING': return '#1C9CF8';
                case 'FAILED': return '#C92614';
                case 'FINALIZED': return '#10B981';
                default: return '';
            }
        },
        formatAnnulledDate(timestamp) {
            return dayjs(timestamp).format('DD. MM. YYYY HH:mm:ss')
        },
    },

    created() {
        this.init();
    },
};
</script>

<style lang="scss">

.ticket-card {
    background: #FFFFFF;
    border: $black-300 1px solid;

    &.annulled {
        background: $black-100;
    }

    .q-card-section {
        border-bottom: $black-200 1px solid;
    }
}

</style>