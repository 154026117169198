import Vue from 'vue';
import Vuex from 'vuex';
import { make } from 'vuex-pathify';

import pathify from '@/libs/pathify';


Vue.use(Vuex);


function getInitialState() {
    return {
        uberEvents: null,
        venues: null,
        promoters: null,
        genres: null,
        globalDiscounts: null,
        sales: null,
        users: null,
        settings: null,

        lastFetch: {
            uberEvents: null,
            venues: null,
            promoters: null,
            genres: null,
            globalDiscounts: null,
            sales: null,
            users: null,
            settings: null,
        },

        selectedUberEvent: null,
        selectedEvent: null,
        selectedTicketGroup: null,
        selectedVenue: null,
        selectedPromoter: null,
        selectedGenre: null,
        selectedUser: null,
        selectedSale: null,

        loggedIn: false,

        eventTreeOpen: false,
        eventTree: {
            showAllUberEvents: true,
            showAllEvents: true,
            showAllTicketGroups: true,
        },
    };
}

const mutations = {
    ...make.mutations(getInitialState()),

    resetState(state) {
        Object.assign(state, getInitialState());
    },
};


export default function (/* { ssrContext } */) {
    const Store = new Vuex.Store({
        modules: {},
        state: getInitialState(),
        mutations,
        plugins: [pathify.plugin],
        strict: process.env.DEV,
    });

    return Store;
}
